import React, { useState, useEffect, useContext, useRef } from "react"; // Import useRef aqui
import { makeStyles } from "@material-ui/core/styles";
import { TextField, InputAdornment, Button, Paper, Table, TableBody, TableCell, TableRow, Avatar, IconButton, CircularProgress } from "@material-ui/core";
import { Search as SearchIcon, DeleteOutline as DeleteOutlineIcon, Edit as EditIcon } from "@material-ui/icons";
import MainHeader from "../../components/MainHeader";
import MainHeaderButtonsWrapper from "../../components/MainHeaderButtonsWrapper";
import Title from "../../components/Title";
import { i18n } from "../../translate/i18n";
import api from "../../services/api";
import toastError from "../../errors/toastError";
import TableRowSkeleton from "../../components/TableRowSkeleton";
import MainContainer from "../../components/MainContainer";
import ContactModal from "../../components/ContactModal";
import ConfirmationModal from "../../components/ConfirmationModal";
import { AuthContext } from "../../context/Auth/AuthContext";
import { toast } from "react-toastify";
import { Link } from "react-router-dom";
import { FiEdit3 } from "react-icons/fi";
import { GoTrash } from "react-icons/go";
import { AiOutlineWhatsApp } from "react-icons/ai";
import { CSVLink } from "react-csv";


const useStyles = makeStyles((theme) => ({
  pagination: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginTop: "2rem",
    gap: "1rem",
  },
  loadingOverlay: {
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    backgroundColor: "rgba(255, 255, 255, 0.7)",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  searchWrapper: {
    display: "flex",
    alignItems: "center",
    width: "100%",
    gap: "4rem"
  },
  buttonGroup: {
    display: "flex",
    gap: "10px",
  },
  contactsHeader: {
    '& > div':{
      flexFlow:"column",
      alignItems:"flex-start"
    },
  },
  mainPaper: {
    flex: 1,
    padding: theme.spacing(1),
    overflowY: "scroll",
    ...theme.scrollbarStyles,
    marginTop:64
  },
}));

const Contacts = () => {
  const classes = useStyles();
  const { user } = useContext(AuthContext);
  const fileUploadRef = useRef(null); 

  const [loading, setLoading] = useState(false);
  const [pageNumber, setPageNumber] = useState(1);
  const [contacts, setContacts] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [searchNumber, setSearchNumber] = useState("");
  const [totalPages, setTotalPages] = useState(1);
  const [contactTicket, setContactTicket] = useState(null);
  const [newTicketModalOpen, setNewTicketModalOpen] = useState(false);
  const [selectedContactId, setSelectedContactId] = useState(null);
  const [contactModalOpen, setContactModalOpen] = useState(false);
  const [confirmOpen, setConfirmOpen] = useState(false);
  const [deletingContact, setDeletingContact] = useState(null);
  const itemsPerPage = 10;
  const { profile } = user;

  const fetchContacts = async () => {
    setLoading(true);
    try {
      const url = user.companyId !== 1 ? "/contacts" : "/contacts/list";
      const { data } = await api.get(url, {
        params: {
          page: pageNumber,
          limit: itemsPerPage,
          name: searchTerm || undefined,
          number: searchNumber || undefined,
        },
      });
      setContacts(data.contacts);
      setTotalPages(Math.ceil(data.totalContacts / itemsPerPage));
    } catch (err) {
      if (err.response && err.response.data && err.response.data.message) {
        toastError(err.response.data.message);
      } else {
        toastError(err);
      }
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchContacts(); 
  }, [pageNumber, searchTerm, searchNumber, user.companyId]);

  const handleSearch = (e) => {
    const value = e.target.value;
    const isNumber = /^[0-9]+$/.test(value);

    if (value === "") {
      setSearchTerm("");
      setSearchNumber("");
      setPageNumber(1);
    } else if (!isNumber) {
      setSearchTerm(value);
      setSearchNumber("");
      setPageNumber(1);
    } else {
      setSearchNumber(value);
      setSearchTerm("");
      setPageNumber(1);
    }
  };

  const handleOpenContactModal = () => {
    setSelectedContactId(null);
    setContactModalOpen(true);
  };

  const handleEditContact = (contactId) => {
    setSelectedContactId(contactId);
    setContactModalOpen(true);
  };

  const handleImportContacts = async (e) => {
    if (!e.target.files[0]) return;
    try {
      const file = e.target.files[0];
      const formData = new FormData();
      formData.append("file", file);
      await api.post("/contacts/import", formData);
      await fetchContacts();
      toast.success(i18n.t("contacts.toasts.imported"));
    } catch (err) {
      toastError(i18n.t("contacts.toasts.importError"));
    }
    e.target.value = null;
  };

  const handleDeleteContact = async () => {
    if (!deletingContact) return;
    try {
      await api.delete(`/contacts/${deletingContact.id}`);
      setContacts((prev) => prev.filter((contact) => contact.id !== deletingContact.id));
      toast.success(i18n.t("contacts.toasts.deleted"));
    } catch (err) {
      if (err.response && err.response.data && err.response.data.message) {
        toastError(err.response.data.message);
    } else {
        toastError(err);
    }
    } finally {
      setDeletingContact(null);
      setConfirmOpen(false);
    }
  };

  const handleDeleteButtonClick = (contact) => {
    setDeletingContact(contact);
    setConfirmOpen(true);
  };

  const handlePageChange = (direction) => {
    setPageNumber((prevPageNumber) => {
      const newPageNumber = prevPageNumber + direction;
      if (newPageNumber >= 1 && newPageNumber <= totalPages) {
        return newPageNumber;
      }
      return prevPageNumber;
    });
  };

  return (
    <MainContainer>
      <ContactModal open={contactModalOpen} contactId={selectedContactId} onClose={() => setContactModalOpen(false)} />
      <ConfirmationModal open={confirmOpen} title={i18n.t("contacts.confirmationModal.deleteTitle")} onClose={() => setConfirmOpen(false)} onConfirm={handleDeleteContact}>
        {i18n.t("contacts.confirmationModal.deleteMessage")}
      </ConfirmationModal>
      <div className={classes.contactsHeader}>
        <MainHeader>
          <Title>{i18n.t("contacts.title")}</Title>
          <MainHeaderButtonsWrapper>
            <div className={classes.searchWrapper}>
              <TextField
                placeholder={i18n.t("contacts.searchPlaceholder")}
                type="search"
                value={searchTerm || searchNumber}
                onChange={handleSearch}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon style={{ color: "gray" }} />
                    </InputAdornment>
                  ),
                }}
              />

              <div className={classes.buttonGroup}>
                <Button variant="contained" color="primary" onClick={handleOpenContactModal} style={{ marginRight: 10 }}>
                  {i18n.t("contacts.buttons.add")}
                </Button>

                <Button variant="contained" color="primary" onClick={() => fileUploadRef.current.click()} style={{ marginRight: 10 }}>
                  {i18n.t("contacts.buttons.import")}
                </Button>

                <CSVLink separator=";" filename="contacts.csv" data={contacts.map((contact) => ({
                  name: contact.name,
                  number: contact.number,
                  email: contact.email,
                }))}>
                  <Button variant="contained" color="primary" style={{ marginRight: 10 }}>
                    {i18n.t("contacts.buttons.export")}
                  </Button>
                </CSVLink>
              </div>
            </div>
            <input type="file" ref={fileUploadRef} style={{ display: "none" }} onChange={handleImportContacts} accept=".csv" />
          </MainHeaderButtonsWrapper>
        </MainHeader>
      </div>

      <Paper variant="outlined" style={{ borderRadius: "15px", position: "relative" }} className={classes.mainPaper}>
        {loading && (
          <div className={classes.loadingOverlay}>
            <CircularProgress />
          </div>
        )}

        <Table size="small">
          <TableBody>
            {contacts.map((contact) => (
              <TableRow key={contact.id}>
                <TableCell style={{ paddingRight: 0 }}>
                  <Avatar src={contact.profilePicUrl} />
                </TableCell>
                <TableCell>{contact.name}</TableCell>
                <TableCell align="center">{contact.number}</TableCell>
                <TableCell align="center">{contact.email}</TableCell>
                <TableCell align="center">
                  {contact.tickets.length > 0 && (
                    <IconButton size="small" component={Link} to={`/tickets/${contact.tickets[0]?.uuid}`}>
                      <AiOutlineWhatsApp />
                    </IconButton>
                  )}
                  <IconButton size="small" onClick={() => handleEditContact(contact.id)}>
                    <FiEdit3 />
                  </IconButton>
                  {profile === "admin" && (
                    <IconButton size="small" onClick={() => handleDeleteButtonClick(contact)}>
                      <GoTrash />
                    </IconButton>
                  )}
                </TableCell>
              </TableRow>
            ))}
            {loading && <TableRowSkeleton avatar columns={3} />}
          </TableBody>
        </Table>
      </Paper>

      <div className={classes.pagination}>
        <Button variant="outlined" disabled={pageNumber === 1} onClick={() => handlePageChange(-1)}>
          Anterior
        </Button>
        <span>
          Página {pageNumber} de {totalPages}
        </span>
        <Button variant="outlined" disabled={pageNumber === totalPages} onClick={() => handlePageChange(1)}>
          Próxima
        </Button>
      </div>
    </MainContainer>
  );
};

export default Contacts;
