import React, { useContext, useEffect, useReducer, useState } from "react";
import { Link as RouterLink, useHistory } from "react-router-dom";
import { GrSend } from "react-icons/gr";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import ListSubheader from "@material-ui/core/ListSubheader";
import Divider from "@material-ui/core/Divider";
import { Backdrop, Badge, Box, Button, CircularProgress, Collapse, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, List, TextField } from "@material-ui/core";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { TiContacts } from "react-icons/ti";
import AnnouncementIcon from "@material-ui/icons/Announcement";
import {AttachFile} from '@material-ui/icons';
import { IoMdSettings } from "react-icons/io";
import { MdPlaylistAddCheckCircle, MdOutlineSendAndArchive } from "react-icons/md";
import { MdOutlinePhonelink } from "react-icons/md";
import { RxDashboard } from "react-icons/rx";
import { FaUserTimes, FaWhatsapp } from "react-icons/fa";
import { TbBellExclamation } from "react-icons/tb";
import { TbUserCancel } from "react-icons/tb";
import { AiOutlineTags } from "react-icons/ai";
import { GrSchedulePlay } from "react-icons/gr";
import { SlDocs } from "react-icons/sl";
import { FaTasks } from "react-icons/fa";
import { HiOutlineUserGroup } from "react-icons/hi2";
import { IoSettingsOutline } from "react-icons/io5";
import { PiDevices } from "react-icons/pi";
import { TbReportSearch } from "react-icons/tb";
import { PiFileCsvLight, PiQueue, PiUsersThreeBold } from "react-icons/pi";
import { i18n } from "../translate/i18n";
import { WhatsAppsContext } from "../context/WhatsApp/WhatsAppsContext";
import { AuthContext } from "../context/Auth/AuthContext";
import { Can } from "../components/Can";
import { SocketContext } from "../context/Socket/SocketContext";
import { isArray } from "lodash";
import api from "../services/api";
import toastError from "../errors/toastError";
import { makeStyles } from "@material-ui/core/styles";
import usePlans from "../hooks/usePlans";
import Typography from "@material-ui/core/Typography";
import useVersion from "../hooks/useVersion";
import { HiOutlineDocumentReport } from "react-icons/hi";
import { TbReport } from "react-icons/tb";
import jsPDF from "jspdf";
import moment from "moment";
import { TbReportAnalytics } from "react-icons/tb";
import { VscNotebookTemplate } from "react-icons/vsc";
import Templates from "../pages/Templates/Templates";
import {useMediaQuery} from "@material-ui/core";
import { Tooltip } from "@material-ui/core";
import clsx from 'clsx'
import { TbMessage2Cog } from "react-icons/tb";
import { MdOutlineContacts } from "react-icons/md";
import { VscSettings } from "react-icons/vsc";
import { TbDashboard } from "react-icons/tb";
import WhatsAppIcon from "@material-ui/icons/WhatsApp";
import { BsLightningCharge } from "react-icons/bs";

const useStyles = makeStyles((theme) => ({
  ListSubheader: {
    height: 26,
    marginTop: "-15px",
    marginBottom: "-10px",
  },
  sideMenu:{
    '& > li > a':{
      minHeight:"75px !important",
      paddingLeft:"24px !important"
    },
    '& > div':{
      minHeight:"75px !important",
      // paddingLeft:"24px !important"
    },
    '& .MuiCollapse-wrapperInner > div > li > a ':{
      minHeight:"50px !important",
    }
  },
  sideMenuItemsResponsive:{
    fill: theme.palette.secondary.main,
    color: theme.palette.secondary.main,
    fontSize: 27,
    minHeight:50,
    lineHeight: "24px",
    [theme.breakpoints.down('sm')]: {
      display: "none"
    },
  }
}));


function ListItemLink(props) {
  const { icon, primary, to, className } = props;

  const renderLink = React.useMemo(
    () =>
      React.forwardRef((itemProps, ref) => (
        <RouterLink to={to} ref={ref} {...itemProps} />
      )),
    [to]
  );

  return (
    <li>
      <ListItem button dense component={renderLink} className={className}>
        {icon ? <ListItemIcon>{icon}</ListItemIcon> : null}
        <ListItemText primary={primary} style={{ marginLeft: "-12px", fontWeight:"bold"}} />
      </ListItem>
    </li>
  );
}

const reducer = (state, action) => {
  if (action.type === "LOAD_CHATS") {
    const chats = action.payload;
    const newChats = [];

    if (isArray(chats)) {
      chats.forEach((chat) => {
        const chatIndex = state.findIndex((u) => u.id === chat.id);
        if (chatIndex !== -1) {
          state[chatIndex] = chat;
        } else {
          newChats.push(chat);
        }
      });
    }

    return [...state, ...newChats];
  }

  if (action.type === "UPDATE_CHATS") {
    const chat = action.payload;
    const chatIndex = state.findIndex((u) => u.id === chat.id);

    if (chatIndex !== -1) {
      state[chatIndex] = chat;
      return [...state];
    } else {
      return [chat, ...state];
    }
  }

  if (action.type === "DELETE_CHAT") {
    const chatId = action.payload;

    const chatIndex = state.findIndex((u) => u.id === chatId);
    if (chatIndex !== -1) {
      state.splice(chatIndex, 1);
    }
    return [...state];
  }

  if (action.type === "RESET") {
    return [];
  }

  if (action.type === "CHANGE_CHAT") {
    const changedChats = state.map((chat) => {
      if (chat.id === action.payload.chat.id) {
        return action.payload.chat;
      }
      return chat;
    });
    return changedChats;
  }
};

const MainListItems = (props) => {
  const classes = useStyles();
  const { drawerClose, collapsed } = props;
  const { whatsApps } = useContext(WhatsAppsContext);
  const { user, handleLogout } = useContext(AuthContext);
  const [connectionWarning, setConnectionWarning] = useState(false);
  const [openCampaignSubmenu, setOpenCampaignSubmenu] = useState(false);
  const [showCampaigns, setShowCampaigns] = useState(false);
  const [showTemplate, setShowTemplate] = useState(false);
  const [showApiCPC, setShowApiCPC] = useState(false);
  const [showKanban, setShowKanban] = useState(false);
  const [showOpenAi, setShowOpenAi] = useState(false);
  const [showIntegrations, setShowIntegrations] = useState(false);
  const history = useHistory();
  const [inputValue, setInputValue] = useState('');
  const [loading, setLoading] = useState(false);
  const isMobile = useMediaQuery('(max-width:768px)');
  const [showSchedules, setShowSchedules] = useState(false);
  const [showInternalChat, setShowInternalChat] = useState(false);
  const [showExternalApi, setShowExternalApi] = useState(false);
  const [openPopupMessages, setOpenPopupMessages] = useState(false);
  const [invisible, setInvisible] = useState(true);
  const [pageNumber, setPageNumber] = useState(1);
  const [searchParam] = useState("");
  const [chats, dispatch] = useReducer(reducer, []);
  const { getPlanCompany } = usePlans();
  const [version, setVersion] = useState(false);
  const { getVersion } = useVersion();
  const socketManager = useContext(SocketContext);
  const [openCampaignsSubmenu, setOpenCampaignsSubmenu] = useState(false);
  const [openRelatoriesSubmenu, setOpenRelatoriesSubmenu] = useState(false);
  


  const [openHistoryModal, setOpenHistoryModal] = useState(false); 

  const handleHistoryClick = () => {
    setOpenHistoryModal(true); 
  };

  const handleInputChange = (event) => {
    setInputValue(event.target.value);
  };

  const handleNavigateToHistory = () => {
    history("/history/relatories"); 
  };


  const handleOpenPopupMessages = () => setOpenPopupMessages(true);
  const handleClosePopupMessages = () => setOpenPopupMessages(false);

  const formatToBrazilTime = (dateString) => {
		const date = new Date(dateString); 
		date.setHours(date.getHours()); 

		const formattedDate = date.toLocaleString("pt-BR", {
		  timeZone: "America/Sao_Paulo",
		  year: "numeric",
		  month: "2-digit",
		  day: "2-digit",
		  hour: "2-digit",
		  minute: "2-digit",
		  second: "2-digit",
		});
	  
		return formattedDate;
	  };

    const handleGenerateCSV = async () => {
      setLoading(true);
      try {
        const response = await api.get("/tickets/list", {
          headers: {
            'Authorization': `Bearer ${localStorage.getItem('token')}`,
            'Content-Type': 'application/json',
          },
        });
  
        if (response.status !== 200 || !Array.isArray(response.data.tickets)) {
          throw new Error("Erro ao buscar os tickets");
        }
    
        const tickets = response.data.tickets;
  
        const ticketGroups = {};
    
        tickets.forEach(ticket => {
          const companyName = ticket.company?.name || "Empresa não encontrada";
          const cpnTicketsValue = ticket.cpnTickets ? "true" : "false";
          const cpnTicketsDate = ticket.cpnTickets && ticket.cpnTickets.date
            ? moment(ticket.cpnTickets.date).format("YYYY-MM-DD")
            : "";
    
          if (cpnTicketsValue === "true" && cpnTicketsDate) {
            if (!ticketGroups[companyName]) {
              ticketGroups[companyName] = {};
            }
            if (!ticketGroups[companyName][cpnTicketsDate]) {
              ticketGroups[companyName][cpnTicketsDate] = 0;
            }
            ticketGroups[companyName][cpnTicketsDate]++;
          }
        });
  
        const csvData = [];
        for (const companyName in ticketGroups) {
          for (const date in ticketGroups[companyName]) {
            const count = ticketGroups[companyName][date];
            csvData.push([companyName, count, date]);
          }
        }
  
        const headers = ["Empresa", "Envio", "Data do Envio"];
        const csvContent = [headers, ...csvData].map(e => e.join(";")).join("\n");
    
        const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8" });
    
        const link = document.createElement("a");
        link.href = URL.createObjectURL(blob);
        link.download = "tickets.csv"; 
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link); 
    
      } catch (error) {
        toastError("Erro ao gerar o CSV: " + error.message);
      } finally {
        setLoading(false);
      }
    };

  const handleGenerateHistory = () => {
    setOpenHistoryModal(false); 
  };

  const handleDownloadPDF = async (number) => {
		try {
			const response = await api.get(`/pdf/${number}`);
	
			const messages = response.data.messages;
      const users = response.data.responseUsers;
   
      
      if(!users[0]){
        toastError("Não existe um ticket aberto ou fechado para este Número!");
        return;
      }
	
			if (!Array.isArray(messages)) {
				toastError("Os dados de mensagens não estão no formato esperado.");
				return;
			}
	
			if (messages.length === 0) {
				toastError("Nenhuma mensagem encontrada para este número.");
				return;
			}
	
			const doc = new jsPDF();
			doc.setFontSize(12);
			doc.text(`Histórico de Mensagens - Número: ${number} | Atendente: ${users[0].name}`, 10, 10);
			doc.line(10, 15, 200, 15);
	
			let yPosition = 20;
			const lineHeight = 10;
			const pageHeight = 280;
	
			messages.forEach((message, index) => {
				const author = message.contact?.name || users[0].name; 
				const content = message.body || "Sem conteúdo"; 
				const formattedDate = formatToBrazilTime(message.createdAt); 
			  
				const messageText = `${index + 1}. ${author}: ${content} - ${formattedDate}`;
			  
				const splitText = doc.splitTextToSize(messageText, 180);
			  
				splitText.forEach(line => {
				  if (yPosition + lineHeight > pageHeight) {
					doc.addPage();
					yPosition = 10;
				  }
				  doc.text(line, 10, yPosition);
				  yPosition += lineHeight;
				});
			});

			doc.save(`Histórico de Mensagens do Número:${number}.pdf`);
		} catch (err) {
			toastError("Erro ao gerar o PDF. Tente novamente.");
		}
	};

  useEffect(() => {
    async function fetchVersion() {
      const _version = await getVersion();
      setVersion(_version.version);
    }
    fetchVersion();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
 

  useEffect(() => {
    dispatch({ type: "RESET" });
    setPageNumber(1);
  }, [searchParam]);

  useEffect(() => {
    async function fetchData() {
      const companyId = user.companyId;
      const planConfigs = await getPlanCompany(undefined, companyId);
  
      setShowCampaigns(planConfigs.plan.useCampaigns);
      setShowKanban(planConfigs.plan.useKanban);
      setShowOpenAi(planConfigs.plan.useOpenAi);
      setShowIntegrations(planConfigs.plan.useIntegrations);
      setShowSchedules(planConfigs.plan.useSchedules);
      setShowInternalChat(planConfigs.plan.useInternalChat);
      setShowExternalApi(planConfigs.plan.useExternalApi);
      setShowTemplate(planConfigs.plan.useTemplate); 
      setShowApiCPC(planConfigs.plan.useApiCPC);
    }
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);



  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      fetchChats();
    }, 500);
    return () => clearTimeout(delayDebounceFn);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchParam, pageNumber]);

  useEffect(() => {
    const companyId = localStorage.getItem("companyId");
    const socket = socketManager.getSocket(companyId);

    socket.on(`company-${companyId}-chat`, (data) => {
      if (data.action === "new-message") {
        dispatch({ type: "CHANGE_CHAT", payload: data });
      }
      if (data.action === "update") {
        dispatch({ type: "CHANGE_CHAT", payload: data });
      }
    });
    return () => {
      socket.disconnect();
    };
  }, [socketManager]);

  useEffect(() => {
    let unreadsCount = 0;
    if (chats.length > 0) {
      for (let chat of chats) {
        for (let chatUser of chat.users) {
          if (chatUser.userId === user.id) {
            unreadsCount += chatUser.unreads;
          }
        }
      }
    }
    if (unreadsCount > 0) {
      setInvisible(false);
    } else {
      setInvisible(true);
    }
  }, [chats, user.id]);

  useEffect(() => {
    if (localStorage.getItem("cshow")) {
      setShowCampaigns(true);
    }
  }, []);

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      if (whatsApps.length > 0) {
        const offlineWhats = whatsApps.filter((whats) => {
          return (
            whats.status === "qrcode" ||
            whats.status === "PAIRING" ||
            whats.status === "DISCONNECTED" ||
            whats.status === "TIMEOUT" ||
            whats.status === "OPENING"
          );
        });
        if (offlineWhats.length > 0) {
          setConnectionWarning(true);
        } else {
          setConnectionWarning(false);
        }
      }
    }, 2000);
    return () => clearTimeout(delayDebounceFn);
  }, [whatsApps]);

  const fetchChats = async () => {
    try {
      const { data } = await api.get("/chats/", {
        params: { searchParam, pageNumber },
      });
      dispatch({ type: "LOAD_CHATS", payload: data.records });
    } catch (err) {
      toastError(err);
    }
  };

  const handleClickLogout = () => {
    //handleCloseMenu();
    handleLogout();
  };

  return (
    <div onClick={drawerClose} className={classes.sideMenu}>
      <Can
        role={user.profile}
        perform="dashboard:view"
        yes={() => (
          <ListItemLink
            to="/"
            primary="Dashboard"
            icon={
              <Tooltip title="Dashboard" arrow>
                <span>
                  <TbDashboard className={classes.sideMenuItemsResponsive} style={{fill:'transparent'}}/>
                </span>
              </Tooltip>
            }
          />
        )}
      />

      {!user.superbp && (
        <ListItemLink
          to="/tickets"
          primary={i18n.t("mainDrawer.listItems.tickets")}
          icon={
            <Tooltip title="Chat de atendimento" arrow>
            <span>
              <WhatsAppIcon className={classes.sideMenuItemsResponsive}/>
            </span>
          </Tooltip>}
        />
      )}

      {!user.superbp && (
        <ListItemLink
          to="/quick-messages"
          primary={i18n.t("mainDrawer.listItems.quickMessages")}
          icon={
            <Tooltip title="Respostas rápidas" arrow>
              <span>
                <BsLightningCharge className={classes.sideMenuItemsResponsive}/>
              </span>
            </Tooltip>
          }
        />
      )}

      <ListItemLink
        to="/contacts"
        primary={i18n.t("mainDrawer.listItems.contacts")}
        icon={
          <Tooltip title="Base de clientes" arrow>
            <span>
              <TiContacts className={classes.sideMenuItemsResponsive}/>
            </span>
          </Tooltip>
        }
      />

      <ListItemLink
        to="/schedules"
        primary={i18n.t("mainDrawer.listItems.schedules")}
        icon={
          <Tooltip title="Agendamentos" arrow>
            <span>
              <GrSchedulePlay className={classes.sideMenuItemsResponsive}/>
            </span>
          </Tooltip>
        }
      />

      {!user.superbp && (
        <ListItemLink
          className="tabulacao"
          to="/tags"
          primary={i18n.t("mainDrawer.listItems.tags")}
          icon={
            <Tooltip title="Tabulações" arrow>
              <span>
                <AiOutlineTags className={classes.sideMenuItemsResponsive}/>
              </span>
            </Tooltip>
          }
        />
      )}

      <Can
        role={user.profile}
        perform="drawer-admin-items:view"
        yes={() => (
          <>
            <Divider />

            <ListSubheader
              hidden={collapsed}
              style={{
                position: "relative",
                fontSize: "17px",
                textAlign: "left",
                paddingLeft: 20
              }}
              inset
              color="inherit"
            >
              {i18n.t("mainDrawer.listItems.administration")}
            </ListSubheader>

            <Divider />

            <ListSubheader
              hidden={collapsed}
              style={{
                position: "relative",
                fontSize: "17px",
                textAlign: "left",
                paddingLeft: 20
              }}
              inset
              color="inherit"
            >
              {i18n.t("mainDrawer.listItems.administration")}
            </ListSubheader>


      {!user.superbp && (
        <ListItemLink
          className="Blacklist"
          to="/blacklist"
          primary={i18n.t("Blacklist")}
          icon={
            <Tooltip title="Blacklist" arrow>
              <span>
                <TbUserCancel className={classes.sideMenuItemsResponsive} style={{fill:'transparent'}}/>
              </span>
            </Tooltip>
          }
        />
      )}


            {user.super && showCampaigns && (
              <>
                {user.super && (
                 
                  <ListItem
                    button
                    onClick={() => setOpenRelatoriesSubmenu(prev => !prev)}
                  >
                    <ListItemIcon>
                      <Tooltip arrow title="Relatórios">
                        <span>
                        <TbReportAnalytics style={{ fontSize: "27px",position:"relative",left:2 }} />
                        </span>
                      </Tooltip>
                    </ListItemIcon>
                    <ListItemText
                      primary={i18n.t("mainDrawer.listItems.relatories")}
                      style={{
                        fontWeight: "bold",
                        fontSize: "14px",
                        display: "flex",
                        alignItems: "center",
                        marginLeft: "-15px"
                      }}
                    />
                    {openRelatoriesSubmenu ? (
                      <ExpandLessIcon />
                    ) : (
                      <ExpandMoreIcon />
                    )}
                  </ListItem>
                  
                )}
                <Collapse
                  in={openRelatoriesSubmenu}
                  timeout="auto"
                  unmountOnExit
                  style={{backgroundColor:"#e4e2ed", borderRadius:9}}
                >
                  <List component="div" disablePadding>
                    {user.super && (
                      <ListItemLink
                        button
                        onClick={handleNavigateToHistory}
                        to="/history/relatories"
                        icon={
                          <Tooltip title="Histórico de Converas" arrow>
                            <span>
                              <TbReportSearch style={{ fontSize: "27px" }} />
                            </span>
                          </Tooltip>
                        }
                        primary={i18n.t("mainDrawer.listItems.history")}
                        style={{
                          fontWeight: "bold",
                          fontSize: "14px",
                          marginLeft: "-15px",
                          display: "flex",
                          alignItems: "center"
                        }}
                      />
                    )}

                    {user.super && (
                      <ListItemLink
                        button
                        onClick={handleNavigateToHistory}
                        to="/template/relatories"
                        icon={
                          <Tooltip title="Relatório de Templates" arrow>
                            <span>
                              <HiOutlineDocumentReport style={{ fontSize: "27px" }} />
                            </span>
                          </Tooltip>
                        }
                        primary={i18n.t("mainDrawer.listItems.templates")}
                        style={{
                          fontWeight: "bold",
                          fontSize: "14px",
                          marginLeft: "-15px",
                          display: "flex",
                          alignItems: "center"
                        }}
                      />
                    )}

                    
                    {/* {user.super && (
                      <ListItemLink
                        to="/test"
                        icon={
                          <Tooltip title="Todos os Relatórios" arrow>
                            <span>
                              <TbReport style={{ fontSize: "27px" }} />
                            </span>
                          </Tooltip>
                        }
                        primary={i18n.t("mainDrawer.listItems.relatoriesFull")}
                        style={{
                          fontWeight: "bold",
                          fontSize: "14px",
                          marginLeft: "-15px",
                          display: "flex",
                          alignItems: "center"
                        }}
                      />
                    )} */}

                    {user.super && (
                      <ListItemLink
                        onClick={handleGenerateCSV}
                        to="/shooting"
                        icon={
                          <Tooltip title="Relatório de Disparo" arrow>
                            <span>
                            <MdOutlineSendAndArchive style={{ fontSize: "27px" }} />
                            </span>
                          </Tooltip>
                        }
                        primary={i18n.t("mainDrawer.listItems.shooting")}
                        style={{
                          fontWeight: "bold",
                          fontSize: "14px",
                          marginLeft: "-15px",
                          display: "flex",
                          alignItems: "center"
                        }}
                      />
                    )}

                    {user.super && (
                      <ListItemLink
                        button
                        onClick={handleNavigateToHistory}
                        to="/csvReport"
                        icon={
                          <Tooltip title="Relatório CSV">
                            <span>
                              <PiFileCsvLight
                                style={{ fontSize: "27px" }}
                              />
                            </span>
                          </Tooltip>
                        }
                        primary={i18n.t("mainDrawer.listItems.csv")}
                        style={{
                          fontWeight: "bold",
                          fontSize: "14px", 
                          marginLeft: "-15px",
                          display: "flex",
                          alignItems: "center"
                        }}
                      />
                    )}
                  </List>
                </Collapse>
              </>
            )}

            {showCampaigns && !isMobile && (
              <>
                  <ListItem
                    className={clsx(classes.sideMenuItemsResponsive,classes.disparoMassivo)}
                    button
                    onClick={() => setOpenCampaignSubmenu(prev => !prev)}
                  >
                    <ListItemIcon>
                      <Tooltip title="Disparo massivo" arrow>
                        <span>
                          <GrSend className={clsx(classes.sideMenuItemsResponsive,'campaign-icon')}/>
                        </span>
                      </Tooltip>
                      
                    </ListItemIcon>
                    <ListItemText
                      style={{marginLeft:"-15px"}}
                      primary={i18n.t("mainDrawer.listItems.campaigns")}
                    />
                    {openCampaignSubmenu ? (
                      <ExpandLessIcon />
                    ) : (
                      <ExpandMoreIcon />
                    )}
                  </ListItem>
                <Collapse
                  in={openCampaignSubmenu}
                  timeout="auto"
                  unmountOnExit
                  style={{backgroundColor:"#e4e2ed", borderRadius:9}}
                >
                  <form onClick={drawerClose} className="sideMenuMid">
                    <List component="div" disablePadding className="teste">
                      <ListItem
                        onClick={() => history.push("/campaigns")}
                        button
                      >
                        <ListItemIcon>
                          <Tooltip title="Gerir Disparo Massivo" arrow>
                            <span>
                              <TbMessage2Cog className={classes.sideMenuItemsResponsive} style={{fill:'transparent'}}/>
                            </span>
                          </Tooltip>
                        </ListItemIcon>
                        <ListItemText primary="Gerir Disparo Massivo" />
                      </ListItem>
                      <ListItem
                        onClick={() => history.push("/contact-lists")}
                        button
                      >
                        <ListItemIcon>
                          <Tooltip title="Lista de contatos" arrow>
                            <span>
                              <MdOutlineContacts className={classes.sideMenuItemsResponsive}/>
                            </span>
                          </Tooltip>
                        </ListItemIcon>
                        <ListItemText primary="Listas de Contatos" />
                      </ListItem>
                      <ListItem
                        onClick={() => history.push("/campaigns-config")}
                        button
                      >
                        <ListItemIcon>
                          <Tooltip title="Configurações" arrow>
                            <span>
                              <VscSettings className={classes.sideMenuItemsResponsive}/>
                            </span>
                          </Tooltip>
                        </ListItemIcon>
                        <ListItemText primary="Configurações" />
                      </ListItem>
                    </List>
                  </form>
                </Collapse>
              </>
            )}

            {user.super && (
              <ListItemLink
                className="informativos"
                to="/announcements"
                primary={i18n.t("mainDrawer.listItems.annoucements")}
                icon={
                  <Tooltip title="Informativos" arrow>
                    <span>
                      <TbBellExclamation style={{ fontSize: "27px" }} />
                    </span>
                  </Tooltip>
                }
              />
            )}

            <ListItemLink
              to="/connections"
              primary={i18n.t("mainDrawer.listItems.connections")}
              icon={
                <Tooltip title="Dispositivos" arrow>
                  <span>
                    <Badge badgeContent={connectionWarning ? "!" : 0} color="error">
                      <PiDevices style={{ fontSize: "27px" }} />
                    </Badge>
                  </span>
                </Tooltip>
              }
            />

            <ListItemLink
              to="/files"
              primary={i18n.t("mainDrawer.listItems.files")}
              icon={
                <Tooltip title="Documentos e Arquivos" arrow>
                  <span>
                    <AttachFile style={{ fontSize: "27px" }} />
                  </span>
                </Tooltip>
              }
            />

            {!user.superbp && (
              <ListItemLink
                to="/queues"
                primary={i18n.t("mainDrawer.listItems.queues")}
                icon={
                  <Tooltip title="Segmentos" arrow>
                    <span>
                      <PiQueue style={{ fontSize: "27px" }} />
                    </span>
                  </Tooltip>
                }
              />
            )}

            {!user.superbp && (
              <ListItemLink
                className="users"
                to="/users"
                primary={i18n.t("mainDrawer.listItems.users")}
                icon={
                  <Tooltip title="Gestão de Operadores" arrow>
                    <span>
                      <PiUsersThreeBold style={{ fontSize: "27px" }} />
                    </span>
                  </Tooltip>
                }
              />
            )}
          
          {showTemplate && (
            <ListItemLink
              className="templates"
              to="/templates"
              primary={i18n.t("mainDrawer.listItems.templates")}
              icon={
                <Tooltip title="Templates" arrow>
                  <span>
                    <VscNotebookTemplate style={{ fontSize: "27px" }} />
                  </span>
                </Tooltip>
              }
            />
          )}
            <ListItemLink
              to="/settings"
              primary={i18n.t("mainDrawer.listItems.settings")}
              icon={
                <Tooltip title="Configurações" arrow>
                  <span>
                    <IoSettingsOutline style={{ fontSize: "27px" }} />
                  </span>
                </Tooltip>
              }
            />

            {!collapsed && (
              <React.Fragment>
                <Divider />
                {/* 
              // IMAGEM NO MENU
              <Hidden only={['sm', 'xs']}>
                <img style={{ width: "100%", padding: "10px" }} src={logo} alt="image" />            
              </Hidden> 
              */}
                <Typography
                  style={{
                    fontSize: "15px",
                    padding: "10px",
                    textAlign: "right",
                    fontWeight: "bold"
                  }}
                >
                  {`${version}`}
                </Typography>
              </React.Fragment>
            )}
          </>
        )}
      />
    </div>
  );
};

export default MainListItems;