import React, { useState, useEffect } from "react";
import * as Yup from "yup";
import { Formik, Form, Field } from "formik";
import { toast } from "react-toastify";
import { makeStyles } from "@material-ui/core/styles";
import { green } from "@material-ui/core/colors";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import CircularProgress from "@material-ui/core/CircularProgress";
import { i18n } from "../../translate/i18n";
import api from "../../services/api";
import toastError from "../../errors/toastError";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
  },
  btnWrapper: {
    position: "relative",
  },
  buttonProgress: {
    color: green[500],
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12,
  },
}));

const BlackListModal = ({ open, onClose, entry = null, onAddOrUpdate }) => {
  const classes = useStyles();
  const initialState = entry
    ? { number: entry.number, reason: entry.reason }
    : { number: "", reason: "" };

  const validationSchema = Yup.object().shape({
    number: Yup.string(),
    reason: Yup.string()
  });

  const handleSave = async (values) => {
    try {
      const payload = { reason: values.reason || "Adição manual" };
  
      if (entry) {
        if (values.number !== entry.number) {
          payload.number = values.number; 
        }
        await api.put(`/blacklist/${entry.number}`, payload);
  
        toast.success(i18n.t("Número da blacklist atualizado com sucesso!"));
        onAddOrUpdate({
          ...entry,
          number: values.number,
          reason: values.reason, 
        });
      } else {
        const { data } = await api.post("/blacklist", { number: values.number, ...payload });
        toast.success("Número adicionar na blacklist com sucesso!");
        onAddOrUpdate(data);
      }

      onClose();
    } catch (err) {
      if (err.response && err.response.data && err.response.data.message) {
        toastError(err.response.data.message);
    } else {
        toastError(err);
    }
    }
  };
  
  return (
    <Dialog open={open} onClose={onClose} maxWidth="xs" fullWidth>
      <DialogTitle>
        {entry ? i18n.t("Adicionar um número na blacklist") : i18n.t("Adicionar um número na blacklist")}
      </DialogTitle>
      <Formik
        initialValues={initialState}
        validationSchema={validationSchema}
        onSubmit={(values, actions) => {
          handleSave(values);
          actions.setSubmitting(false);
        }}
      >
        {({ touched, errors, isSubmitting }) => (
          <Form>
            <DialogContent>
              <Field
                as={TextField}
                label={i18n.t("Número")}
                name="number"
                error={touched.number && Boolean(errors.number)}
                helperText={touched.number && errors.number}
                variant="outlined"
                margin="dense"
                fullWidth
              />
              <Field
                as={TextField}
                label={i18n.t("Motivo")}
                name="reason"
                error={touched.reason && Boolean(errors.reason)}
                helperText={touched.reason && errors.reason}
                variant="outlined"
                margin="dense"
                fullWidth
              />
            </DialogContent>
            <DialogActions>
              <Button onClick={onClose} color="secondary" disabled={isSubmitting} variant="outlined">
                {i18n.t("Cancelar")}
              </Button>
              <Button
                type="submit"
                color="primary"
                disabled={isSubmitting}
                variant="contained"
                className={classes.btnWrapper}
              >
                {entry ? i18n.t("Adicionar") : i18n.t("Adicionar")}
                {isSubmitting && <CircularProgress size={24} className={classes.buttonProgress} />}
              </Button>
            </DialogActions>
          </Form>
        )}
      </Formik>
    </Dialog>
  );
};

export default BlackListModal;