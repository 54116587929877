import React, { useState } from 'react';
import { IconButton } from '@material-ui/core';
import { VolumeUp, VolumeOff } from '@material-ui/icons';
import Slider from '@material-ui/core/Slider';

const NotificationsVolume = ({ volume, setVolume }) => {
  const [previousVolume, setPreviousVolume] = useState(volume);
  const [isMuted, setIsMuted] = useState(false);

  const handleToggleMute = () => {
    if (isMuted) {
      setVolume(previousVolume);
      setIsMuted(false);
    } else {
      setPreviousVolume(volume);
      setVolume(0);
      setIsMuted(true);
    }
    localStorage.setItem("volume", isMuted ? previousVolume : 0);
  };

  const handleVolumeChange = (event, newValue) => {
    setVolume(newValue);
    setIsMuted(newValue === 0);
    localStorage.setItem("volume", newValue);
  };

  return (
    <div style={{ width: "90%", display: "flex", alignItems: "center" }}>
      <IconButton onClick={handleToggleMute}>
        {isMuted || volume === 0 ? <VolumeOff /> : <VolumeUp />}
      </IconButton>
      <Slider
        value={volume}
        onChange={handleVolumeChange}
        aria-labelledby="continuous-slider"
        min={0}
        max={1}
        step={0.1}
      />
    </div>
  );
};

export default NotificationsVolume;