import React from "react";

import { makeStyles } from "@material-ui/core/styles";
import './style.css'

const useStyles = makeStyles(theme => ({
	contactsHeader: {
		width: "calc(100% - 90px)",
		display: "flex",
		alignItems: "center",
		justifyContent: "flex-start",
		height:58,
		gap:16,
		marginLeft:9,
		'& > div':{
			gap: 16,
			flexWrap: "nowrap",
			display: "flex"
		},
		'& > div > div':{
			maxWidth:"max-content",
			alignItems: "center",
			display: "flex"
		},
		'& h5':{
			[theme.breakpoints.down('md')]:{
				fontSize: 16,
			}
		},
		'& button > span':{
			whiteSpace: "nowrap"
		}
	},
}));

const MainHeader = ({ children }) => {
	const classes = useStyles();

	return <div className={classes.contactsHeader}>{children}</div>;
};	

export default MainHeader;
