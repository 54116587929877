"use client"

import React from "react"
import { useContext, useEffect, useRef, useState } from "react"
import { useHistory } from "react-router-dom"
import { Tooltip, Typography } from "@material-ui/core"
import { makeStyles } from "@material-ui/core/styles"
import Paper from "@material-ui/core/Paper"
import SearchIcon from "@material-ui/icons/Search"
import InputBase from "@material-ui/core/InputBase"
import Tabs from "@material-ui/core/Tabs"
import Tab from "@material-ui/core/Tab"
import Badge from "@material-ui/core/Badge"
import { IoIosSearch } from "react-icons/io"
import FormControlLabel from "@material-ui/core/FormControlLabel"
import { LuFilter } from "react-icons/lu"
import NewTicketModal from "../NewTicketModal"
import TicketsList from "../TicketsListCustom"
import TabPanel from "../TabPanel"
import clsx from "clsx"
import { i18n } from "../../translate/i18n"
import { AuthContext } from "../../context/Auth/AuthContext"
import { Can } from "../Can"
import TicketsQueueSelect from "../TicketsQueueSelect"
import { Button } from "@material-ui/core"
import { TagsFilter } from "../TagsFilter"
import { UsersFilter } from "../UsersFilter"
import "./style.css"
import { FaRegCircle } from "react-icons/fa6"
import { PiQueue } from "react-icons/pi"

const useStyles = makeStyles((theme) => ({
  ticketsWrapper: {
    position: "relative",
    display: "flex",
    height: "100%",
    flexDirection: "column",

    overflow: "hidden",
    borderTopRightRadius: 0,
    borderBottomRightRadius: 0,
    borderRadius: 0,
  },

  tabsHeader: {
    flex: "none",
    backgroundColor: "#fff",
    display: "flex",
    position: "fixed",
    zIndex: "9",
    width: "100%",
    top: 0,
    boxShadow: "3px 3px 6px 0 #00000050",
  },

  tabsInternal: {
    flex: "none",
    backgroundColor: "red",
  },

  settingsIcon: {
    alignSelf: "center",
    marginLeft: "auto",
    padding: 8,
  },

  tab: {
    width: 120,
    height: 58,
    minWidth: 0,
    "&.Mui-selected": {
      color: theme.palette.secondary.main,
    },
  },

  internalTab: {
    minWidth: 120,
    width: 120,
    padding: 5,
  },

  ticketOptionsBox: {
    "& > #actions": {
      display: "flex",
      alignItems: "flex-end",
      padding: theme.spacing(1),
      zIndex: "9",
      backgroundColor: "transparent",
    },
    "& > #actions > div": {
      boxShadow: "1px 1px 3px 1px #00000075",
    },
  },

  // ticketOptionsBox: {
  //   '& > #actions':{
  //     display: "flex",
  //     alignItems: "flex-end",
  //     padding: theme.spacing(1),
  //     position:"absolute",
  //     bottom:16,
  //     zIndex:"9",
  //     right: 0,
  //     backgroundColor:"transparent"
  //   },
  //   '& > #actions > div':{
  //     boxShadow:"1px 1px 3px 1px #00000075"
  //   }
  // },

  ticketSearchLine: {
    padding: theme.spacing(1),
  },

  serachInputWrapper: {
    flex: 1,
    background: theme.palette.total,
    display: "flex",
    borderRadius: 40,
    padding: 4,
    marginRight: theme.spacing(1),
    marginTop: theme.spacing(1),
  },
  toogleAll: {
    color: "#999",
    flexDirection: "row",
    margin: 0,
  },
  ticketsSearch: {},
  searchIcon: {
    color: "grey",
    alignSelf: "center",
    borderRadius: "50%",
    width: 40,
    height: 40,
    marginRight: ".5rem",
    padding: ".5rem",
  },

  searchInput: {
    flex: 1,
    border: "none",
    borderRadius: 30,
    height: "42px",
    border: "1px solid",
    padding: "0 1rem",
    width: "100% !important",
  },

  insiderTabPanel: {
    height: "100%",
    marginTop: "-72px",
    paddingTop: "72px",
  },

  insiderDoubleTabPanel: {
    display: "flex",
    flexDirection: "column",
    marginTop: "-72px",
    paddingTop: "72px",
    height: "100%",
  },

  labelContainer: {
    width: "auto",
    padding: 0,
  },
  iconLabelWrapper: {
    flexDirection: "row",
    "& > *:first-child": {
      marginBottom: "3px !important",
      marginRight: 16,
    },
  },
  insiderTabLabel: {
    [theme.breakpoints.down(1600)]: {
      display: "none",
    },
  },
  smallFormControl: {
    "& .MuiOutlinedInput-input": {
      padding: "12px 10px",
    },
    "& .MuiInputLabel-outlined": {
      marginTop: "-6px",
    },
  },

  //12-02-2025 - Eliézer
  newTicketButton: {
    width: 36,
    height: 36,
    fontSize: 0,
    minWidth: 0,
    borderRadius: "50%",
    boxShadow: "1px 1px 3px 1px #00000050",
    backgroundColor: `${theme.palette.primary.main} !important`,
    "&::before": {
      fontSize: 24,
      content: '"+"',
    },
  },
  filter: {
    marginRight: "3rem",
    width: 36,
    height: 36,
    backgroundColor: theme.palette.primary.main,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    cursor: "pointer",
    borderRadius: "50%",
    boxShadow: "1px 1px 3px 1px #00000050",
    "& svg": {
      strokeWidth: 2,
      stroke: "#fff",
      fontSize: 18,
    },
    "&.active > div": {
      visibility: "visible",
      opacity: "1",
    },
  },
  actions: {
    display: "flex",
    alignItems: "center",
    gap: 16,
    margin: "0 3rem 0 0",
  },
  filterContent: {
    minWidth: 300,
    position: "fixed",
    top: 58,
    zIndex: 9,
    backgroundColor: "#fff",
    padding: 16,
    borderRadius: "9px",
    border: "3px solid #999",
    display: "flex",
    flexFlow: "column",
    gap: 16,
    transition: "visibility .3s, opacity .3s",
    visibility: "hidden",
    opacity: "0",
    boxShadow: "3px 3px 6px 0 #00000050",
    "&.visible": {
      visibility: "visible",
      opacity: "1",
    },
    "& label > span": {
      fontSize: 0,
      "&::before": {
        fontSize: 15,
        content: '"Exibir todas as mensagens"',
      },
    },
    "& .MuiFormControl-root": {
      width: "100%",
    },
    "& .MuiInputBase-root": {
      width: "100%",
    },
  },
  toogleCheckBox: {
    width: 24,
    height: 24,
    marginRight: 8,
    fill: "#bdbdbd",
    "&.ativo": {
      fill: "#80DA5A",
      borderRadius: "50%",
      backgroundColor: "#a0e981",
    },
  },
  ticketsInChat: {
    position: "absolute",
    zIndex: "9",
    left: 16,
    backgroundColor: "#fff",
    border: "2px solid #0241CA",
    borderRadius: "9px",
  },
  navButtonChatStatus: {
    minWidth: 0,
    height: 40,
    width: 40,
    transition: "transform .3s",
    borderRadius: "50%",
    "& svg": {
      fill: "black",
      fontSize: 24,
    },
    "&.ativo": {
      transform: "rotate(90deg)",
    },
    "&hover": {
      border: "2px solid #0241CA",
    },
  },
  filterIcons: {
    fill: theme.palette.primary.main,
    width: 27,
    height: 27,
    borderRadius: "50%",
    strokeWidth: "0 !important",
  },
  filterItems: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "center",
    gap: 16,
    "&:nth-child(2) > div svg ": {
      stroke: "#999",
      fill: "#999",
      position: "relative",
      top: 0,
    },
  },
  navigationTab: {
    alignItems: "center",
    width: 800,
    height: 61,
    '& [aria-label="icon label tabs example"]': {
      alignItems: "center",
    },
  },
  tabWithIcon: {
    display: "flex",
    alignItems: "center",
    gap: "8px",
    "& svg": {
      fontSize: 20,
    },
  },
  filterText: {
    position: "absolute",
    right: "-4rem",
    color: theme.palette.primary.main,
  },
}))

const TicketsManagerTabs = () => {
  const classes = useStyles()
  const history = useHistory()
  const filterRef = useRef(null)

  const [searchParam, setSearchParam] = useState("")
  const [tab, setTab] = useState("open")
  const [tabOpen, setTabOpen] = useState("open")
  const [newTicketModalOpen, setNewTicketModalOpen] = useState(false)
  const [showAllTickets, setShowAllTickets] = useState(false)
  const searchInputRef = useRef()
  const { user } = useContext(AuthContext)
  const { profile } = user

  const [openCount, setOpenCount] = useState(0)
  const [pendingCount, setPendingCount] = useState(0)

  const userQueueIds = user.queues.map((q) => q.id)
  const [selectedQueueIds, setSelectedQueueIds] = useState(userQueueIds || [])
  const [selectedTags, setSelectedTags] = useState([])
  const [selectedUsers, setSelectedUsers] = useState([])
  const [selectedWhatsapps, setSelectedWhatsapps] = useState([])

  const handleSelectedWhatsapps = (selecteds) => {
    const whatsapps = selecteds.map((w) => w.id)
    setSelectedWhatsapps(whatsapps)
  }

  useEffect(() => {
    if (user.profile.toUpperCase() === "ADMIN") {
      setShowAllTickets(true)
    } else {
      setShowAllTickets(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user.profile])

  useEffect(() => {
    setShowAllTickets(false)
  }, [selectedQueueIds]);

  useEffect(() => {
    if (tab === "search") {
      searchInputRef.current.focus()
    }
  }, [tab])

  const [isActive, setIsActive] = useState(false)

  // Adicionar event listener para detectar cliques fora do filtro
  useEffect(() => {
    function handleClickOutside(event) {
      // Verificar se o clique foi fora do filterRef
      if (filterRef.current && !filterRef.current.contains(event.target)) {
        // Verificar se o clique foi em um elemento do Material-UI Select
        const isSelectElement =
          event.target.closest(".MuiPopover-root") ||
          event.target.closest(".MuiSelect-root") ||
          event.target.closest(".MuiMenuItem-root") ||
          event.target.closest(".MuiList-root") ||
          event.target.closest(".MuiBackdrop-root")

        // Só fechar se não for um elemento do select
        if (!isSelectElement) {
          setIsActive(false)
        }
      }
    }

    // Adicionar o event listener quando o filtro estiver ativo
    if (isActive) {
      document.addEventListener("mousedown", handleClickOutside)
    }

    // Cleanup do event listener
    return () => {
      document.removeEventListener("mousedown", handleClickOutside)
    }
  }, [isActive])

  let searchTimeout

  // Manter a aba ativa durante a alteração do filtro
  useEffect(() => {
    if (selectedQueueIds.length > 0) {
      setTab("open")  // Mantenha a aba "open" ativa ao alterar os filtros de segmento
    }
  }, [selectedQueueIds])

  const handleSearch = (e) => {
    const searchedTerm = e.target.value.toLowerCase()
    clearTimeout(searchTimeout)
    if (searchedTerm === "") {
      setSearchParam(searchedTerm)
      setTab("open")
      return
    }
    searchTimeout = setTimeout(() => {
      setSearchParam(searchedTerm)
    }, 500)
  }

  const handleChangeTab = (e, newValue) => {
    setTab(newValue)
  }

  const handleChangeTabOpen = (e, newValue) => {
    setTabOpen(newValue)
  }

  const applyPanelStyle = (status) => {
    if (tabOpen !== status) {
      return { width: 0, height: 0 }
    }
  }

  const handleCloseOrOpenTicket = (ticket) => {
    setNewTicketModalOpen(false)
    if (ticket !== undefined && ticket.uuid !== undefined) {
      history.push(`/tickets/${ticket.uuid}`)
    }
  }

  const handleSelectedTags = (selecteds) => {
    const tags = selecteds.map((t) => t.id)
    setSelectedTags(tags)
  }

  const handleSelectedUsers = (selecteds) => {
    const users = selecteds.map((t) => t.id)
    setSelectedUsers(users)
  }

  const toogleFilterClass = () => {
    setIsActive(!isActive)
  }

  // Replace these two lines
  const [isSelected, setIsSelected] = useState(true)
  const toogleShowAllTickets = () => {
    setShowAllTickets((prevState) => !prevState)
    setIsSelected((prevState) => !prevState)
  }

  return (
    <Paper elevation={0} variant="outlined" className={classes.ticketsWrapper}>
      <NewTicketModal
        modalOpen={newTicketModalOpen}
        onClose={(ticket) => {
          setNewTicketModalOpen(false);
          if (ticket !== undefined && ticket.uuid !== undefined) {
            history.push(`/tickets/${ticket.uuid}`);
          }
        }}
      />
      <Paper elevation={0} square className={classes.tabsHeader}>
        <Tabs
          className={classes.navigationTab}
          value={tab}
          onChange={handleChangeTab}
          variant="fullWidth"
          indicatorColor="primary"
          textColor="primary"
          aria-label="icon label tabs example"
        >
            <Tab
              value={"open"}
              label={
                <Badge className={classes.badge} badgeContent={openCount} color="primary">
                  {i18n.t("ticketsList.assignedHeader")}
                </Badge>
              }
              classes={{ root: classes.tab }}
            />
            <Tab
              value={"pending"}
              label={
                <Badge className={classes.badge} badgeContent={pendingCount} color="secondary">
                  {i18n.t("ticketsList.pendingHeader")}
                </Badge>
              }
              classes={{ root: classes.tab }}
            />
            <Tab value={"closed"} label={i18n.t("tickets.tabs.closed.title")} classes={{ root: classes.tab }} />
            <Tab
              value={"search"}
              label={
                <div className={classes.tabWithIcon}>
                  <IoIosSearch />
                  {i18n.t("tickets.tabs.search.title")}
                </div>
              }
              classes={{ root: classes.tab }}
            />

            <div id="actions" className={classes.actions}>
              <Tooltip title="Nova Conversa" arrow>
                <Button
                  className={classes.newTicketButton}
                  variant="outlined"
                  color="primary"
                  onClick={() => setNewTicketModalOpen(true)}
                >
                  {i18n.t("ticketsManager.buttons.newTicket")}
                </Button>
              </Tooltip>

              <div ref={filterRef} className={clsx(classes.filter, isActive ? "active" : "")}>
                <Tooltip title="Filtros" arrow>
                  <Button onClick={toogleFilterClass}>
                    <LuFilter />
                    <Typography className={classes.filterText}>Filtros</Typography>
                  </Button>
                </Tooltip>

                <div className={clsx(classes.filterContent, isActive ? "visible" : "")}>
                  <div className={classes.filterItems}>
                    {/*<MdAlignHorizontalLeft className={classes.filterIcons} />*/}
                    <Can
                      role={user.profile}
                      perform="tickets-manager:showall"
                      yes={() => (
                        <FormControlLabel
                          className={classes.toogleAll}
                          label={i18n.t("tickets.buttons.showAll")}
                          labelPlacement="start"
                          control={
                            <FaRegCircle
                              className={clsx(classes.toogleCheckBox, showAllTickets ? "ativo" : "inativo")}
                              size="small"
                              checked={showAllTickets}
                              onClick={toogleShowAllTickets}
                              name="showAllTickets"
                              color="primary"
                            />
                          }
                        />
                      )}
                    />
                  </div>

                  <div className={classes.filterItems}>
                    <PiQueue className={classes.filterIcons} />
                    {user.queues?.length > 0 ? (
                      <TicketsQueueSelect
                        style={{ marginLeft: 6 }}
                        selectedQueueIds={selectedQueueIds}
                        userQueues={user?.queues}
                        onChange={(values) => setSelectedQueueIds(values)}
                      />
                    ) : (
                      <Typography style={{ color: "#999", marginLeft: 6 }}>Nenhum segmento disponível</Typography>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </Tabs>
        </Paper>
        <Paper square elevation={0} className={classes.ticketOptionsBox}>
          {tab === "search" ? (
            <div className={classes.serachInputWrapper}>
              <SearchIcon className={classes.searchIcon} />
              <span className="searchInputCamp"></span>
              <InputBase
                className={classes.searchInput}
                inputRef={searchInputRef}
                placeholder={i18n.t("tickets.search.placeholder")}
                type="search"
                onChange={handleSearch}
              />
            </div>
          ) : (
            <div style={{ display: "none" }}></div>
          )}
        </Paper>
        <TabPanel value={tab} name="open" className={classes.ticketsWrapper}>
          <Paper className={classes.ticketsWrapper}>
            <TicketsList
              status="open"
              showAll={showAllTickets}
              selectedQueueIds={selectedQueueIds}
              updateCount={(val) => setOpenCount(val)}
            />
          </Paper>
        </TabPanel>
        <TabPanel value={tab} name="pending" className={classes.ticketsWrapper}>
          <Paper className={classes.ticketsWrapper}>
            <TicketsList
              status="pending"
              selectedQueueIds={selectedQueueIds}
              updateCount={(val) => setPendingCount(val)}
            />
          </Paper>
        </TabPanel>
        <TabPanel value={tab} name="closed" className={classes.ticketsWrapper}>
          <TicketsList status="closed" showAll={true} selectedQueueIds={selectedQueueIds} />
        </TabPanel>
        <TabPanel value={tab} name="search" className={classes.ticketsWrapper}>
          <TagsFilter onFiltered={handleSelectedTags} />
          {profile === "admin" && <UsersFilter onFiltered={handleSelectedUsers} />}
          <TicketsList
            searchParam={searchParam}
            showAll={true}
            tags={selectedTags}
            users={selectedUsers}
            selectedQueueIds={selectedQueueIds}
            className={classes.ticketsSearch}
          />
        </TabPanel>
      </Paper>
    )
}

export default TicketsManagerTabs
