import React, { useState, useContext, useEffect } from "react";
import clsx from "clsx";
import {
  makeStyles,
  Drawer,
  AppBar,
  Toolbar,
  List,
  Typography,
  Divider,
  MenuItem,
  IconButton,
  Menu,
  useTheme,
  useMediaQuery,
  Tooltip,
  Paper,
  Button
} from "@material-ui/core";
import { RiUser4Line } from "react-icons/ri";
import { TbSettings2 } from "react-icons/tb";
import MenuIcon from "@material-ui/icons/Menu";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import AccountCircle from "@material-ui/icons/AccountCircle";
import CachedIcon from "@material-ui/icons/Cached";
import { MdOutlineRefresh } from "react-icons/md";
import MainListItems from "./MainListItems";
import NotificationsPopOver from "../components/NotificationsPopOver";
import NotificationsVolume from "../components/NotificationsVolume";
import UserModal from "../components/UserModal";
import { AuthContext } from "../context/Auth/AuthContext";
import BackdropLoading from "../components/BackdropLoading";
import { i18n } from "../translate/i18n";
import toastError from "../errors/toastError";
import AnnouncementsPopover from "../components/AnnouncementsPopover";
import ChatPopover from "../pages/Chat/ChatPopover";
import logo from "../assets/logo.png";
import { SocketContext } from "../context/Socket/SocketContext";
import './style.css'
import { useDate } from "../hooks/useDate";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import ColorModeContext from "../layout/themeContext";
import DarkModeToggle from "../components/DarkModeToggle";
import { IoIosClose } from "react-icons/io";

import ThemeToggleButton from "../components/ThemeToggleButton/ThemeToggleButton"

const drawerWidth = 90;

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    height: "100vh",
    [theme.breakpoints.down("sm")]: {
      height: "calc(100vh - 56px)",
    },
    backgroundColor: theme.palette.fancyBackground,
    '& .MuiButton-outlinedPrimary': {
      color: theme.mode === 'light' ? '#FFF' : '#FFF',
	    backgroundColor: theme.mode === 'light' ? '#a70c35' : '#1c1c1c',
      //border: theme.mode === 'light' ? '1px solid rgba(0 124 102)' : '1px solid rgba(255, 255, 255, 0.5)',
    },
    '& .MuiTab-textColorPrimary.Mui-selected': {
      color: theme.mode === 'light' ? '#a70c35' : '#FFF',
    }
  },
  avatar: {
    width: "100%",
  },
  toolbar: {
    paddingRight: 24, // keep right padding when drawer closed
    color: theme.palette.dark.main,
    background: theme.palette.barraSuperior,
  },
  toolbarIcon: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    marginLeft:16,
    padding: "0 8px",
    minHeight: "48px",
    [theme.breakpoints.down("sm")]: {
      height: "48px"
    }
  },
  appBar: {
    [theme.breakpoints.up('sm')]: {
      width:"calc(100% - 90px)",
      top:"-63px",
      left:73,
      backgroundColor:"#fff",
      transition:"top 1s",
      paddingLeft:16,
      boxShadow:"3px 3px 6px 0 #00000050"
    },
    [theme.breakpoints.down('sm')]: {
      justifyContent: 'space-between',
    },
    boxShadow:"none",
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    [theme.breakpoints.down("sm")]: {
      display: "none"
    }
  },
  menuButton: {
    marginRight: 36,
  },
  menuButtonHidden: {
    display: "none",
  },
  title: {
    flexGrow: 1,
    fontSize: 14,
    color: "white",
  },
  drawerPaper: {
    position: "relative",
    whiteSpace: "nowrap",
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    [theme.breakpoints.down("sm")]: {
      width: "100%"
    },
    ...theme.scrollbarStylesSoft
  },
  drawerPaperClose: {
    overflowX: "hidden",
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    width: "60px !important",
    [theme.breakpoints.up("sm")]: {
      width: theme.spacing(9),
    },
    [theme.breakpoints.down("sm")]: {
      width: "100%"
    }
  },
  content: {
    flex: 1,
    overflow: "auto",
    marginLeft:90,

  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  paper: {
    padding: theme.spacing(2),
    display: "flex",
    overflow: "auto",
    flexDirection: "column"
  },
  containerWithScroll: {
    flex: 1,
    padding: theme.spacing(1),
    overflowY: "scroll"
  },
  NotificationsPopOver: {
    // color: theme.barraSuperior.secondary.main,
  },
  logo: {
    width: "80%",
    height: "auto",
    maxWidth: 180,
    [theme.breakpoints.down("sm")]: {
      width: "auto",
      height: "80%",
      maxWidth: 180,
    },
    logo: theme.logo
  },
  headerMenu:{
    height:42,
    position:"fixed",
    top:9,
    right:18,
    boxShadow:"none",
    display:"flex",
    flexDirection:"row-reverse",
    backgroundColor:"transparent",
    '&.ativo > div':{
      visibility:"visible",
      opacity:"1",
      transition:"visibility .3s, opacity .3s",
    },
    '&.inativo > div':{
      transition:"visibility .3s, opacity .3s"
    },
    '& button span':{
      color: theme.palette.primary.main
    }
  },
  headerOptions:{
    width: 240,
    display:"flex",
    flexDirection:"column",
    height: "auto",
    position:"absolute",
    right:5,  
    top:42,  
    backgroundColor:"#fff",
    boxShadow:"1px 1px 5px 1px #00000050",
    borderRadius:12,
    color: "#646464",
    '& button':{
      display:"flex",
      justifyContent:"center",
      width:40,
      height:40,
      padding:0,
      margin:8
    },
    '& button:hover':{
      backgroundColor:theme.palette.primary.main,
    },
    '& button svg':{
      fill:"#646464",
      fontSize: 21
    }
  },
  isDashboard:{
    left:"90px !important",
    top:0,
    transition:"top 1s",
    paddingLeft:0
  },
  headerMenuIco: {
    width:30,
    height:30,
    stroke: theme.palette.primary.main
  },
  newMessageIco:{
    position:"relative",
    bottom:3,
    '& svg':{
      fill: theme.palette.primary.main
    },
    '& .MuiBadge-anchorOriginTopRightRectangular':{
      background:"#fff",
      border:"1px solid"
    }
  },
  headerMenuItem: {
    display:"flex",
    alignItems:"center",
    '& button:hover svg':{
      fill: "#fff !important"
    }
  }
}));

const LoggedInLayout = ({ children }) => {
  const classes = useStyles();
  const [userModalOpen, setUserModalOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [menuOpen, setMenuOpen] = useState(false);
  const { handleLogout, loading } = useContext(AuthContext);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [drawerVariant, setDrawerVariant] = useState("permanent");
  // const [dueDate, setDueDate] = useState("");
  const { user } = useContext(AuthContext);

  const theme = useTheme();
  const { colorMode } = useContext(ColorModeContext);
  const greaterThenSm = useMediaQuery(theme.breakpoints.up("sm"));

  const [volume, setVolume] = useState(localStorage.getItem("volume") || 1);

  const { dateToClient } = useDate();
  const prefersDarkMode = useMediaQuery("(prefers-color-scheme: dark)");
  const [mode, setMode] = useState(prefersDarkMode ? "dark" : "light");


  //################### CODIGOS DE TESTE #########################################
  // useEffect(() => {
  //   navigator.getBattery().then((battery) => {
  //   })
  // }, []);

  // useEffect(() => {
  //   const geoLocation = navigator.geolocation

  //   geoLocation.getCurrentPosition((position) => {
  //     let lat = position.coords.latitude;
  //     let long = position.coords.longitude;
  //   })
  // }, []);

  // useEffect(() => {
  //   const nucleos = window.navigator.hardwareConcurrency;

  // }, []);

  // useEffect(() => {
  //   if (
  //     navigator.userAgent.match(/Android/i)
  //     || navigator.userAgent.match(/webOS/i)
  //     || navigator.userAgent.match(/iPhone/i)
  //     || navigator.userAgent.match(/iPad/i)
  //     || navigator.userAgent.match(/iPod/i)
  //     || navigator.userAgent.match(/BlackBerry/i)
  //     || navigator.userAgent.match(/Windows Phone/i)
  //   ) {
  //   }
  //   else {
  //   }
  // }, []);
  //##############################################################################

  const socketManager = useContext(SocketContext);

  useEffect(() => {
    if (document.body.offsetWidth > 1200) {
      setDrawerOpen(true);
    }
  }, []);

  useEffect(() => {
    if (document.body.offsetWidth < 600) {
      setDrawerVariant("temporary");
    } else {
      setDrawerVariant("permanent");
    }
  }, [drawerOpen]);

  const themeToggle = () => {
    setMode((prevMode) => (prevMode === "light" ? "dark" : "light"));
  }

  useEffect(() => {
    const companyId = localStorage.getItem("companyId");
    const userId = localStorage.getItem("userId");

    const socket = socketManager.getSocket(companyId);

    socket.on(`company-${companyId}-auth`, (data) => {
      if (data.user.id === +userId) {
        toastError("Sua conta foi acessada em outro computador.");
        setTimeout(() => {
          localStorage.clear();
          window.location.reload();
        }, 1000);
      }
    });

    socket.emit("userStatus");
    const interval = setInterval(() => {
      socket.emit("userStatus");
    }, 1000 * 60 * 5);

    return () => {
      socket.disconnect();
      clearInterval(interval);
    };
  }, [socketManager]);

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
    setMenuOpen(true);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
    setMenuOpen(false);
  };

  const handleOpenUserModal = () => {
    setUserModalOpen(true);
    handleCloseMenu();
  };

  const handleClickLogout = () => {
    handleCloseMenu();
    handleLogout();
  };

  const drawerClose = () => {
    if (document.body.offsetWidth < 600) {
      setDrawerOpen(false);
    }
  };

  const handleRefreshPage = () => {
    window.location.reload(false);
  }

  const handleMenuItemClick = () => {
    const { innerWidth: width } = window;
    if (width <= 600) {
      setDrawerOpen(false);
    }
  };

  const toggleColorMode = () => {
    colorMode.toggleColorMode();
  }


 // Estado para controlar a visibilidade do anexarContent
 const [isHeaderMenuOpen, setIsHeaderMenuOpen] = useState(false);
 const [modalAberto, setModalAberto] = useState(false);
 // Função para abrir o anexarContent
 const openHeaderMenuContent = () => {
  setIsHeaderMenuOpen(true);
  setModalAberto(true);
 };

 // Função para fechar o anexarContent
 const closeHeaderMenuContent = () => {
  setIsHeaderMenuOpen(false);
  setModalAberto(false);
 };

  const [isDashboardRendered, setIsDashboardRendered] = useState(false);
  useEffect(() => {
    // Função para verificar se o elemento #dashboard está no DOM
    const checkDashboard = () => {
      const dashboardElement = document.getElementById('dashboard');
      setIsDashboardRendered(!!dashboardElement); // Atualiza o estado
    };

    // Verifica o estado inicial
    checkDashboard();

    // Configura o MutationObserver para monitorar mudanças no DOM
    const observer = new MutationObserver(checkDashboard);

    // Inicia a observação no body para detectar mudanças no DOM
    observer.observe(document.body, {
      childList: true, // Observa adição/remoção de elementos filhos
      subtree: true, // Observa toda a árvore do DOM
    });

    // Limpa o observer ao desmontar o componente
    return () => {
      observer.disconnect();
    };
  }, []);

  const handleClick = () => {
    openHeaderMenuContent();
  };

  if (loading) {
    return <BackdropLoading />;
  }

  const themeD = {
    pallete: {
      type: mode,
      primary: {
        main: mode === "light" ? "#a70c35 !important" : "#FFFF !important",
      },
      background: {
        default: mode === "dark" ? "#f5f5f5 !important" : "#333333 !important",
      }
    }
  };

  return (
    <div className={classes.root}>
      <Drawer
        variant={drawerVariant}
        className={drawerOpen ? classes.drawerPaper : classes.drawerPaperClose}
        classes={{
          paper: clsx(
            classes.drawerPaper,
            !drawerOpen && classes.drawerPaperClose
          )
        }}
        open={drawerOpen}
      >
        <div className={classes.toolbarIcon}>
          <img src={logo} className={classes.logo} alt="logo" />
          <IconButton onClick={() => setDrawerOpen(!drawerOpen)}>
            <ChevronLeftIcon />
            <span>aqui</span>
          </IconButton>
        </div>
        <Divider />
        <List className={classes.containerWithScroll}>
          <MainListItems drawerClose={drawerClose} collapsed={!drawerOpen} />
        </List>
        <Divider />
        {/* <div style={{ padding: "20px" }}>
          <DarkModeToggle />
        </div> */}
      </Drawer>
      <UserModal
        open={userModalOpen}
        onClose={() => setUserModalOpen(false)}
        userId={user?.id}
      />
      <AppBar
        position="absolute"
        className={clsx(
          classes.appBar,
          drawerOpen && classes.appBarShift,
          isDashboardRendered ? classes.isDashboard : ""
        )}
        color="primary"
      >
        <Toolbar variant="dense" className={classes.toolbar}>
          <IconButton
            edge="start"
            variant="contained"
            aria-label="open drawer"
            onClick={() => setDrawerOpen(!drawerOpen)}
            className={clsx(
              classes.menuButton,
              drawerOpen && classes.menuButtonHidden
            )}
          >
            <MenuIcon />
          </IconButton>

          <Typography
            component="h2"
            variant="h6"
            color="inherit"
            noWrap
            className={classes.title}
          >
            {/* {greaterThenSm && user?.profile === "admin" && getDateAndDifDays(user?.company?.dueDate).difData < 7 ? ( */}
            {greaterThenSm &&
            user?.profile === "admin" &&
            user?.company?.dueDate ? (
              <>
                Olá <b>{user.name}</b>, Bem vindo a <b>{user?.company?.name}</b>
                ! (Ativo até {dateToClient(user?.company?.dueDate)})
              </>
            ) : (
              <>
                Olá <b>{user.name}</b>, Bem vindo a <b>{user?.company?.name}</b>
                !
              </>
            )}
          </Typography>

          {/* <IconButton edge="start" onClick={toggleColorMode}>
            {theme.mode === 'dark' ? <Brightness7Icon style={{ color: "white" }} /> : <Brightness4Icon style={{ color: "white" }} />}
          </IconButton> */}
          <Paper
            className={clsx(
              classes.headerMenu,
              "header-menu"
            )}
          >
            {/* <div className={classes.newMessageIco}>
              <ChatPopover />
            </div> */}
            <div className={classes.newMessageIco}>
              {user.id && <NotificationsPopOver volume={volume} />}
            </div>

            <Button onClick={handleClick}>
              <span style={{ marginRight: 8 }}>Configurações</span>
              {isHeaderMenuOpen ? <IoIosClose className={classes.headerMenuIco} /> : <TbSettings2 className={classes.headerMenuIco} />}
            </Button>

            {isHeaderMenuOpen && (
              <ClickAwayListener onClickAway={closeHeaderMenuContent}>
              <div className={classes.headerOptions}>
                <div className={classes.headerMenuItem}>
                  <NotificationsVolume setVolume={setVolume} volume={volume} />
                </div>

                <div className={classes.headerMenuItem}>
                  <IconButton
                    onClick={handleRefreshPage}
                    aria-label={i18n.t("mainDrawer.appBar.refresh")}
                    color="inherit"
                  >
                    <MdOutlineRefresh style={{ color: "white" }} />
                  </IconButton>
                  <span>Recarregar Página</span>
                </div>

                <div className={classes.headerMenuItem}>
                  <IconButton>
                    <AnnouncementsPopover />
                  </IconButton>
                  <span>Informativos</span>
                </div>

                <div className={classes.headerMenuItem}>
                  <IconButton
                    aria-label="account of current user"
                    aria-controls="menu-appbar"
                    aria-haspopup="true"
                    onClick={handleMenu}
                    variant="contained"
                    style={{ color: "white" }}
                  >
                    <RiUser4Line />
                  </IconButton>
                  <span>Perfil</span>
                  <Menu
                    id="menu-appbar"
                    anchorEl={anchorEl}
                    getContentAnchorEl={null}
                    anchorOrigin={{
                      vertical: "bottom",
                      horizontal: "right"
                    }}
                    transformOrigin={{
                      vertical: "top",
                      horizontal: "right"
                    }}
                    open={menuOpen}
                    onClose={handleCloseMenu}
                  >
                    <div className={classes.headerPerfilMenu}>
                      <MenuItem onClick={handleOpenUserModal}>
                        {i18n.t("mainDrawer.appBar.user.profile")}
                      </MenuItem>
                      <MenuItem onClick={handleClickLogout}>
                        {i18n.t("mainDrawer.appBar.user.logout")}
                      </MenuItem>
                    </div>
                  </Menu>
                </div>
              </div>
               </ClickAwayListener>
            )}
          </Paper>
        </Toolbar>
      </AppBar>
      <main className={classes.content}>
        <div className={classes.appBarSpacer} />

        {children ? children : null}
      </main>
    </div>
  );
};

export default LoggedInLayout;