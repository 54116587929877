import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import clsx from 'clsx'

const useStyles = makeStyles(theme => ({
	MainHeaderButtonsWrapper: {
		flex: "none",
		"& > *": {
			margin: theme.spacing(1),
		},
		'& button':{
			fontSize: 12,
			marginRight:"0 !important"
		}
	},
}));

const MainHeaderButtonsWrapper = ({ children }) => {
	const classes = useStyles();

	return <div className={clsx(classes.MainHeaderButtonsWrapper,"main-header")}>{children}</div>;

};

export default MainHeaderButtonsWrapper;
