import React, { useEffect, useState } from 'react';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    LineElement,
    PointElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';
import { Line } from 'react-chartjs-2';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import brLocale from 'date-fns/locale/pt-BR';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { Button, Stack, TextField } from '@mui/material';
import Typography from "@material-ui/core/Typography";
import api from '../../services/api';
import { format } from 'date-fns';
import { toast } from 'react-toastify';
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
    container: {
        paddingTop: theme.spacing(1),
        paddingBottom: theme.padding,
        paddingLeft: theme.spacing(1),
        paddingRight: theme.spacing(2),
    },
    calendarItems: {
        '& > div':{
            width:160,
            height:36,
            borderRadius:9
        }
    },
    
    chartWrapper: {
        position: 'relative',
        overflowX: 'auto',
        overflowY: 'hidden',
        width: '100%',
        height: 300,
        '&::-webkit-scrollbar': {
            height: '6px'
        },
        '&::-webkit-scrollbar-track': {
            background: '#a70c3550'
        },
        '&::-webkit-scrollbar-thumb': {
            background: theme.palette.primary.main,
            borderRadius: '4px'
        },
        '&::-webkit-scrollbar-thumb:hover': {
            background: '#555'
        }
    },
    chartInner: {
        minWidth: '3000px', // Adjust this value based on your needs
        height: '280px'
    },
    buttonHover:{
        backgroundColor:`${theme.palette.primary.main} !important`
    }
}));

ChartJS.register(
    CategoryScale,
    LinearScale,
    LineElement,
    PointElement,
    Title,
    Tooltip,
    Legend
);

export const options = {
    responsive: true,
    plugins: {
        legend: {
            position: 'top',
            display: false,
        },
        title: {
            display: true,
            text: 'Gráfico de Conversas',
            position: 'left',
        },
        datalabels: {
            display: false,
            anchor: 'start',
            offset: -30,
            align: "start",
            color: "#fff",
            textStrokeColor: "#000",
            textStrokeWidth: 2,
            font: {
                size: 20,
                weight: "bold"

            },
        },
        zoom: {
            pan: {
              enabled: true,
              mode: 'x',
            },
            zoom: {
              wheel: {
                enabled: true,
              },
              pinch: {
                enabled: true,
              },
              mode: 'x',
            },
        },
    },
    scales: {
        x: {
            type: 'category',
            offset: true,
            grid: {
              offset: true,
            },
            ticks: {
              maxRotation: 45, // Inclina os rótulos para evitar sobreposição
              minRotation: 0,
            },
          },
          y: {
            ticks: {
              padding: 10,
            },
          },
    },
};

export const ChartsDate = () => {
    const classes = useStyles()
    const [initialDate, setInitialDate] = useState(new Date());
    const [finalDate, setFinalDate] = useState(new Date());
    const [ticketsData, setTicketsData] = useState({ data: [], count: 0 });

    const companyId = localStorage.getItem("companyId");

    useEffect(() => {
        handleGetTicketsInformation();
    }, []);

    const dataCharts = {

        labels: ticketsData && ticketsData?.data.length > 0 && ticketsData?.data.map((item) => (item.hasOwnProperty('horario') ? `Das ${item.horario}:00 as ${item.horario}:59` : item.data)),
        datasets: [
            {
                // label: 'Dataset 1',
                data: ticketsData?.data.length > 0 && ticketsData?.data.map((item, index) => {
                    return item.total
                }),
                backgroundColor: '#fff',
                pointRadius: 5,
                pointHoverRadius: 8,
                borderColor:"#a70c35",
                pointBorderWidth:3,
                tension: 0.4
            },
        ],
    };

    const handleGetTicketsInformation = async () => {
        try {
            const { data } = await api.get(`/dashboard/ticketsDay?initialDate=${format(initialDate, 'yyyy-MM-dd')}&finalDate=${format(finalDate, 'yyyy-MM-dd')}&companyId=${companyId}`);
            setTicketsData(data);
        } catch (error) {
            toast.error('Erro ao buscar informações dos tickets');
        }
    }

    return (
        <>
            {/* <Typography component="h2" variant="h6" color="primary" gutterBottom>
                Total ({ticketsData?.count})
            </Typography> */}

            <Stack direction={'row'} spacing={2} alignItems={'center'} sx={{ my: 2, }} className='dateChart' style={{marginLeft:42}}>
                <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={brLocale} style={{color:"#fff"}}>
                    <DatePicker
                        value={initialDate}
                        onChange={(newValue) => { setInitialDate(newValue) }}
                        label="Inicio"
                        renderInput={(params) => <TextField fullWidth {...params} sx={{ width: '20ch' }} />}
                        className={classes.calendarItems}
                    />
                </LocalizationProvider>

                <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={brLocale}>
                    <DatePicker
                        value={finalDate}
                        onChange={(newValue) => { setFinalDate(newValue) }}
                        label="Fim"
                        renderInput={(params) => <TextField fullWidth {...params} sx={{ width: '20ch' }} />}
                        className={classes.calendarItems}
                    />
                </LocalizationProvider>

                <Button className={classes.buttonHover} onClick={handleGetTicketsInformation} variant='contained' >Filtrar</Button>

            </Stack>
            <div className={classes.chartWrapper}>
                <div className={classes.chartInner}>
                    <Line options={options} data={dataCharts} style={{ maxWidth: '100%', maxHeight: '280px', }} />
                </div>
            </div>
        </>
    );
}