import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Input, Select, MenuItem, InputAdornment } from "@material-ui/core";
import { Search } from "@material-ui/icons";
import MainContainer from "../../components/MainContainer";
import criarUser1 from "../../../src/assets/videos/users/1.mp4";
import criarUser2 from "../../../src/assets/videos/users/2.mp4";
import criarUser3 from "../../../src/assets/videos/users/3.mp4";
import { ArrowForward, ArrowBack } from "@material-ui/icons";

const useStyles = makeStyles((theme) => ({
  divMain: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "flex-start",
    color: "#333",
    marginTop: "1rem",
    padding: "20px 10px",
  },
  textMain: {
    fontSize: "36px",
    fontWeight: "bold",
    textAlign: "center",
    marginBottom: "1rem",
    color: "#d32f2f",
  },
  subTitle: {
    fontSize: "24px",
    fontWeight: "bold",
    textAlign: "center",
    color: "#d32f2f",
    marginBottom: "2rem",
  },
  searchContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    gap: "1rem",
    marginBottom: "2rem",
    width: "100%",
    maxWidth: "900px",
    flexDirection: "row",
  },
  searchInput: {
    width: "100%",
    maxWidth: "500px",
    padding: "12px 20px",
    border: "1px solid #d32f2f",
    borderRadius: "25px",
    color: "#333",
    fontSize: "16px",
    display: "flex",
    alignItems: "center",
    marginBottom: "1rem",
    "& .MuiInput-underline:before, & .MuiInput-underline:after": {
      border: "none",
    },
  },
  videoContainer: {
    display: "flex",
    overflow: "hidden",
    position: "relative",
    maxWidth: "800px",
    borderRadius: "8px",
  },
  video: {
    width: "100%",
    height: "auto",
    maxWidth: "100%",
    objectFit: "cover",
  },
  selectCategory: {
    width: "100%",
    maxWidth: "200px",
    padding: "12px 20px",
    border: "1px solid #d32f2f",
    borderRadius: "25px",
    color: "#333",
    fontSize: "14px",
    marginBottom: "1rem",
    "& .MuiInput-underline:before, & .MuiInput-underline:after": {
      border: "none",
    },
  },
  faqItemContainer: {
    width: "100%",
    maxWidth: "800px",
    marginBottom: "1.5rem",
    backgroundColor: "#f9f9f9",
    borderRadius: "8px",
    boxShadow: "0 2px 15px rgba(0, 0, 0, 0.1)",
    padding: "20px",
    border: "1px solid #ddd",
    transition: "transform 0.3s ease-in-out, box-shadow 0.3s ease",
    "&:hover": {
      transform: "scale(1.05)",
      boxShadow: "0 4px 20px rgba(0, 0, 0, 0.2)",
    },
  },
  faqQuestion: {
    fontSize: "18px",
    fontWeight: "bold",
    color: "#d32f2f",
    cursor: "pointer",
    marginBottom: "10px",
  },
  faqAnswer: {
    fontSize: "16px",
    color: "#555",
    marginTop: "10px",
    padding: "10px",
    borderTop: "1px solid #ddd",
  },
  noResults: {
    textAlign: "center",
    color: "red",
    marginTop: "2rem",
  },
  videoControls: {
    display: "flex",
    justifyContent: "center",
    gap: "20px",
    marginTop: "1rem",
  },
  arrowButton: {
    backgroundColor: "transparent",
    border: "none",
    color: "#d32f2f",
    cursor: "pointer",
    fontSize: "30px",
    transition: "transform 0.2s ease",
    "&:hover": {
      transform: "scale(1.1)",
    },
  },
}));

const FAQItem = ({ item, isOpen, toggleOpen, index }) => {
  const classes = useStyles();
  const [currentVideoIndex, setCurrentVideoIndex] = useState(0);

  const handleNextVideo = () => {
    setCurrentVideoIndex((prev) => (prev + 1) % 3);
  };

  const handlePrevVideo = () => {
    setCurrentVideoIndex((prev) => (prev - 1 + 3) % 3);
  };

  const videoSources = [criarUser1, criarUser2, criarUser3];
  const targetQuestion = "Como criar um operador dentro da Vend?";  // Nome da pergunta que deve ter vídeos

  return (
    <div className={classes.faqItemContainer}>
      <div className={classes.faqQuestion} onClick={toggleOpen}>
        {item.question}
      </div>
      <div className={`${classes.faqAnswer} ${isOpen ? "open" : ""}`}>
        {isOpen && (
          <div>
            {/* Exibir vídeos apenas para a pergunta "Como criar um operador dentro da Vend?" */}
            {item.question === targetQuestion && (
              <div className={classes.videoContainer}>
                {videoSources.map((source, videoIndex) => (
                  <video
                    key={videoIndex}
                    className={classes.video}
                    muted
                    autoPlay
                    loop
                    style={{
                      display: videoIndex === currentVideoIndex ? "block" : "none",
                    }}
                  >
                    <source src={source} type="video/mp4" />
                    Seu navegador não suporta o formato de vídeo.
                  </video>
                ))}
              </div>
            )}
            {/* Controles dos vídeos */}
            {item.question === targetQuestion && (
              <div className={classes.videoControls}>
                <button
                  className={classes.arrowButton}
                  onClick={handlePrevVideo}
                >
                  <ArrowBack />
                </button>
                <button
                  className={classes.arrowButton}
                  onClick={handleNextVideo}
                >
                  <ArrowForward />
                </button>
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
};


const Faq = () => {
  const classes = useStyles();
  const [openIndex, setOpenIndex] = useState(null);
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedCategory, setSelectedCategory] = useState("Todas");

  const faqData = [
    { question: "Como criar um operador dentro da Vend?", category: "Operadores" },
    { question: "Como deletar um operador dentro da Vend", category: "Operadores" },
    { question: "Como Editar informações de um operador dentro da Vend?", category: "Operadores" },
    { question: "Como Conectar um dispositivo?", category: "Dispositivos" },
    { question: "Por que não é permitido conectar o mesmo número em dispositivos diferentes?", category: "Dispositivos" },
    { question: "Como padronizar um dispositivo a um operador?", category: "Dispositivos" },
    { question: "Limite de tickets atingidos", category: "Possíveis erros" },
    { question: "Insira CPNJ/CPF ou contrato", category: "Possíveis erros" },
    { question: "Finalizar atendimento sem tabulações", category: "Possíveis erros" },
    { question: "Limite de tickets atingidos", category: "Possíveis erros" },
    { question: "Insira CPNJ/CPF ou contrato", category: "Possíveis erros" },
    { question: "Finalizar atendimento sem tabulações", category: "Possíveis erros" },
    { question: "Limite de tickets atingidos", category: "Possíveis erros" },
  ];

  const categories = ["Todas", ...Array.from(new Set(faqData.map((item) => item.category)))];

  const filteredFAQ = faqData.filter(
    (item) =>
      (selectedCategory === "Todas" || item.category === selectedCategory) &&
      (item.question.toLowerCase().includes(searchTerm.toLowerCase()))
  );

  const toggleFAQ = (index) => {
    setOpenIndex(openIndex === index ? null : index);
  };

  return (
    <MainContainer>
      <div className={classes.divMain}>
        <h1 className={classes.textMain}>Central de Ajuda</h1>
        <h2 className={classes.subTitle}>Pergunta Frequente</h2>
        <div className={classes.searchContainer}>
          <Input
            className={classes.searchInput}
            type="text"
            placeholder="Pesquisar FAQ..."
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            startAdornment={
              <InputAdornment position="start">
                <Search style={{ color: "#d32f2f" }} />
              </InputAdornment>
            }
            disableUnderline
          />
          <Select
            className={classes.selectCategory}
            value={selectedCategory}
            onChange={(e) => setSelectedCategory(e.target.value)}
            disableUnderline
          >
            {categories.map((category) => (
              <MenuItem key={category} value={category}>
                {category}
              </MenuItem>
            ))}
          </Select>
        </div>
        {filteredFAQ.length > 0 ? (
  filteredFAQ.map((item, index) => (
    <FAQItem
      key={index}
      item={item}
      index={index} 
      isOpen={openIndex === index}
      toggleOpen={() => toggleFAQ(index)}
    />
  ))
) : (
  <p className={classes.noResults}>Nenhuma pergunta encontrada. Tente uma pesquisa diferente.</p>
)}


      </div>
    </MainContainer>
  );
};

export default Faq;
