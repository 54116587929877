"use client"

import React from "react"
import { useState, useEffect, useReducer, useContext } from "react"
import { toast } from "react-toastify"
import { parse } from "json2csv"

import { makeStyles } from "@material-ui/core/styles"
import Paper from "@material-ui/core/Paper"
import Button from "@material-ui/core/Button"
import Table from "@material-ui/core/Table"
import TableBody from "@material-ui/core/TableBody"
import TableCell from "@material-ui/core/TableCell"
import TableHead from "@material-ui/core/TableHead"
import TableRow from "@material-ui/core/TableRow"
import IconButton from "@material-ui/core/IconButton"
import SearchIcon from "@material-ui/icons/Search"
import TextField from "@material-ui/core/TextField"
import InputAdornment from "@material-ui/core/InputAdornment"
import { GoTrash } from "react-icons/go"
import { FiEdit3 } from "react-icons/fi"
import Tooltip from "@material-ui/core/Tooltip"
import Chip from "@material-ui/core/Chip"
import Badge from "@material-ui/core/Badge"
import MoreHorizIcon from "@material-ui/icons/MoreHoriz"
import Popover from "@material-ui/core/Popover"
import List from "@material-ui/core/List"
import ListItem from "@material-ui/core/ListItem"
import ListItemText from "@material-ui/core/ListItemText"

import MainContainer from "../../components/MainContainer"
import MainHeader from "../../components/MainHeader"
import MainHeaderButtonsWrapper from "../../components/MainHeaderButtonsWrapper"
import Title from "../../components/Title"

import api from "../../services/api"
import { i18n } from "../../translate/i18n"
import TableRowSkeleton from "../../components/TableRowSkeleton"
import UserModal from "../../components/UserModal"
import ConfirmationModal from "../../components/ConfirmationModal"
import toastError from "../../errors/toastError"
import { SocketContext } from "../../context/Socket/SocketContext"
import { AuthContext } from "../../context/Auth/AuthContext"
import { FormControl, Select } from "@material-ui/core"

const reducer = (state, action) => {
  switch (action.type) {
    case "LOAD_USERS":
      const users = Array.isArray(action.payload) ? action.payload : []
      const newUsers = []

      users.forEach((user) => {
        const userIndex = state.findIndex((u) => u.id === user.id)
        if (userIndex !== -1) {
          state[userIndex] = user
        } else {
          newUsers.push(user)
        }
      })

      return [...state, ...newUsers]

    case "UPDATE_USERS":
      const user = action.payload
      const userIndex = state.findIndex((u) => u.id === user.id)
      if (userIndex !== -1) {
        state[userIndex] = user
        return [...state]
      }
      return [user, ...state]

    case "DELETE_USER":
      const userId = action.payload
      const userToDeleteIndex = state.findIndex((u) => u.id === userId)
      if (userToDeleteIndex !== -1) {
        state.splice(userToDeleteIndex, 1)
      }
      return [...state]

    case "RESET":
      return []

    default:
      return state
  }
}

const useStyles = makeStyles((theme) => ({
  mainPaper: {
    flex: 1,
    padding: theme.spacing(1),
    overflowY: "scroll",
    ...theme.scrollbarStyles,
    marginTop:64
  },
  segmentChip: {
    margin: "2px",
    fontWeight: "normal",
    fontSize: "12px",
    height: "26px",
  },
  segmentBadge: {
    marginRight: theme.spacing(1),
    cursor: "pointer",
  },
  segmentPopover: {
    padding: theme.spacing(1),
    maxHeight: "300px",
    overflowY: "auto",
  },
  segmentList: {
    padding: 0,
  },
  segmentListItem: {
    padding: theme.spacing(0.5, 1),
  },
  colorDot: {
    display: "inline-block",
    width: "12px",
    height: "12px",
    borderRadius: "50%",
    marginRight: "8px",
  },
  tableCell: {
    padding: "6px 16px",
  },
  actionButtons: {
    display: "flex",
    justifyContent: "center",
  },
  searchField: {
    marginRight: theme.spacing(1),
    minWidth: "200px",
  },
  companySelect: {
    marginRight: theme.spacing(1),
    minWidth: "200px",
  },
  headerButtons: {
    display: "flex",
    alignItems: "center",
    "& > *": {
      margin: theme.spacing(0.5),
    },
  },
  usersHeader: {
    '& > div':{
      flexFlow:"column",
      alignItems:"flex-start"
    }
  }
}))

const Users = () => {
  const classes = useStyles()

  const [loading, setLoading] = useState(false)
  const [hasMore, setHasMore] = useState(false)
  const [selectedUser, setSelectedUser] = useState(null)
  const [deletingUser, setDeletingUser] = useState(null)
  const [userModalOpen, setUserModalOpen] = useState(false)
  const [confirmModalOpen, setConfirmModalOpen] = useState(false)
  const [searchParam, setSearchParam] = useState("")
  const [users, dispatch] = useReducer(reducer, [])
  const [companies, setCompanies] = useState([])
  const [selectedCompanyId, setSelectedCompanyId] = useState("")
  const [segmentPopoverAnchor, setSegmentPopoverAnchor] = useState(null)
  const [selectedUserSegments, setSelectedUserSegments] = useState([])

  const { user } = useContext(AuthContext)
  const socketManager = useContext(SocketContext)

  useEffect(() => {
    if (user?.companyId === 1) {
      const fetchCompanies = async () => {
        try {
          const endpoint = "/companies/list"
          const { data } = await api.get(endpoint)
          setCompanies(Array.isArray(data) ? data : [])
        } catch (err) {
          if (err.response && err.response.data && err.response.data.message) {
            toastError(err.response.data.message)
          } else {
            toastError(err)
          }
        }
      }
      fetchCompanies()
    }
  }, [user?.companyId])

  useEffect(() => {
    dispatch({ type: "RESET" })
    setLoading(true)

    const delayDebounceFn = setTimeout(() => {
      const fetchUsers = async () => {
        try {
          const url = user.companyId !== 1 ? "/users" : "/users/all"

          const params = {
            name: searchParam,
            companyId: user.companyId !== 1 ? user.companyId : selectedCompanyId || null,
          }

          const { data } = await api.get(url, { params })

          if (user.companyId !== 1) {
            if (Array.isArray(data.users)) {
              dispatch({ type: "LOAD_USERS", payload: data.users })
              setHasMore(data.hasMore)
            } else {
              setHasMore(false)
            }
          } else {
            if (Array.isArray(data)) {
              dispatch({ type: "LOAD_USERS", payload: data })
              setHasMore(false)
            } else {
              setHasMore(false)
            }
          }
        } catch (err) {
          if (err.response && err.response.data && err.response.data.message) {
            toastError(err.response.data.message)
          } else {
            toastError(err)
          }
        } finally {
          setLoading(false)
        }
      }

      fetchUsers()
    }, 500)

    return () => clearTimeout(delayDebounceFn)
  }, [searchParam, selectedCompanyId, user.companyId])

  useEffect(() => {
    const companyId = localStorage.getItem("companyId")
    const socket = socketManager.getSocket(companyId)

    socket.on(`company-${companyId}-user`, (data) => {
      if (data.action === "update" || data.action === "create") {
        dispatch({ type: "UPDATE_USERS", payload: data.user })
      }

      if (data.action === "delete") {
        dispatch({ type: "DELETE_USER", payload: +data.userId })
      }
    })

    return () => {
      socket.disconnect()
    }
  }, [socketManager])

  const handleSearch = (event) => {
    setSearchParam(event.target.value.toLowerCase())
  }

  const handleOpenUserModal = () => {
    setSelectedUser(null)
    setUserModalOpen(true)
  }

  const handleCloseUserModal = () => {
    setSelectedUser(null)
    setUserModalOpen(false)
  }

  const handleEditUser = (user) => {
    setSelectedUser(user)
    setUserModalOpen(true)
  }

  const handleDeleteUser = async (userId) => {
    try {
      await api.delete(`/users/${userId}`)
      toast.success(i18n.t("users.toasts.deleted"))
      dispatch({ type: "DELETE_USER", payload: userId })
    } catch (err) {
      if (err.response && err.response.data && err.response.data.message) {
        toastError(err.response.data.message)
      } else {
        toastError(err)
      }
    }
    setDeletingUser(null)
    setSearchParam("")
  }

  const formatToBrazilTime = (dateString) => {
    const date = new Date(dateString)
    date.setHours(date.getHours())

    const formattedDate = date.toLocaleString("pt-BR", {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit",
    })

    return formattedDate
  }

  const exportToCSV = () => {
    const usersToExport = users.map((user) => ({
      UsuarioID: user.id,
      Nome: user.name,
      Email: user.email,
      Perfil: user.profile,
      Segmento: user.queues.map((queue) => queue.name).join(", ") || "Sem segmento",
      Última_Atualização: formatToBrazilTime(user.updatedAt),
    }))

    try {
      const csv = parse(usersToExport)
      const blob = new Blob([csv], { type: "text/csv;charset=utf-8;" })
      const link = document.createElement("a")
      link.href = URL.createObjectURL(blob)
      link.download = "Usuários.csv"
      link.click()
    } catch (err) {
      if (err.response && err.response.data && err.response.data.message) {
        toastError(err.response.data.message)
      } else {
        toastError(err)
      }
    }
  }

  const handleOpenSegmentPopover = (event, userQueues) => {
    setSegmentPopoverAnchor(event.currentTarget)
    setSelectedUserSegments(userQueues)
  }

  const handleCloseSegmentPopover = () => {
    setSegmentPopoverAnchor(null)
  }

  const renderSegments = (userQueues) => {
    if (!userQueues || userQueues.length === 0) {
      return <span>{i18n.t("Não há segmentos")}</span>
    }

    const visibleSegments = userQueues.slice(0, 2)
    const remainingCount = userQueues.length - 2

    return (
      <div style={{ display: "flex", alignItems: "center", flexWrap: "wrap", justifyContent: "center" }}>
        {visibleSegments.map((queue) => (
          <Tooltip key={queue.id} title={queue.name}>
            <Chip
              className={classes.segmentChip}
              style={{ backgroundColor: queue.color, color: "#fff" }}
              label={queue.name}
              size="small"
            />
          </Tooltip>
        ))}

        {remainingCount > 0 && (
          <Badge
            badgeContent={remainingCount}
            color="primary"
            className={classes.segmentBadge}
            onClick={(e) => handleOpenSegmentPopover(e, userQueues)}
          >
            <Chip
              icon={<MoreHorizIcon />}
              label=""
              size="small"
              className={classes.segmentChip}
              style={{ backgroundColor: "#f0f0f0", minWidth: "32px" }}
            />
          </Badge>
        )}

        <Popover
          open={Boolean(segmentPopoverAnchor)}
          anchorEl={segmentPopoverAnchor}
          onClose={handleCloseSegmentPopover}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "center",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "center",
          }}
        >
          <List className={classes.segmentList}>
            {selectedUserSegments.map((queue) => (
              <ListItem key={queue.id} className={classes.segmentListItem}>
                <span className={classes.colorDot} style={{ backgroundColor: queue.color }} />
                <ListItemText primary={queue.name} />
              </ListItem>
            ))}
          </List>
        </Popover>
      </div>
    )
  }

  return (
    <MainContainer>
      <ConfirmationModal
        title={`${i18n.t("users.confirmationModal.deleteTitle")} ${deletingUser?.name}?`}
        open={confirmModalOpen}
        onClose={() => setConfirmModalOpen(false)}
        onConfirm={() => handleDeleteUser(deletingUser.id)}
      >
        {i18n.t("users.confirmationModal.deleteMessage")}
      </ConfirmationModal>

      <UserModal
        open={userModalOpen}
        onClose={handleCloseUserModal}
        aria-labelledby="form-dialog-title"
        userId={selectedUser?.id}
      />
      <div className={classes.usersHeader}>
        <MainHeader>
          <Title>{i18n.t("users.title")}</Title>
          <MainHeaderButtonsWrapper className={classes.headerButtons}>
            <TextField
              placeholder={i18n.t("contacts.searchPlaceholder")}
              type="search"
              value={searchParam}
              onChange={handleSearch}
              className={classes.searchField}
              variant="outlined"
              size="small"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon style={{ color: "gray" }} />
                  </InputAdornment>
                ),
              }}
            />

            {user.companyId === 1 && (
              <FormControl style={{ marginLeft: "16px" }}>
                <Select
                  labelId="select-company-label"
                  value={selectedCompanyId}
                  onChange={(e) => setSelectedCompanyId(e.target.value)}
                  native
                  style={{width:"auto"}}
                >
                  <option value="">Selecionar Carteira</option>
                  {Array.isArray(companies) && companies.map((company) => (
                    <option key={company.id} value={company.id}>
                      {company.name}
                    </option>
                  ))}
                </Select>
              </FormControl>
            )}

            <Button variant="contained" color="primary" onClick={handleOpenUserModal} style={{ fontWeight: "bold" }}>
              {i18n.t("users.buttons.add")}
            </Button>
            <Button variant="contained" color="primary" onClick={exportToCSV} style={{ fontWeight: "bold" }}>
              {i18n.t("Exportar Informações de Usuários")}
            </Button>
          </MainHeaderButtonsWrapper>
        </MainHeader>
      </div>

      <Paper className={classes.mainPaper} variant="outlined" style={{ borderRadius: "15px" }}>
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell align="center">{i18n.t("users.table.id")}</TableCell>
              <TableCell align="center">{i18n.t("users.table.name")}</TableCell>
              <TableCell align="center">{i18n.t("users.table.email")}</TableCell>
              <TableCell align="center">{i18n.t("Segmentos")}</TableCell>
              <TableCell align="center">{i18n.t("Última atualização")}</TableCell>
              <TableCell align="center">{i18n.t("users.table.profile")}</TableCell>
              <TableCell align="center">{i18n.t("users.table.actions")}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {users.map((user) => (
              <TableRow key={user.id}>
                <TableCell align="center" className={classes.tableCell}>
                  {user.id}
                </TableCell>
                <TableCell align="center" className={classes.tableCell}>
                  {user.name}
                </TableCell>
                <TableCell align="center" className={classes.tableCell}>
                  {user.email}
                </TableCell>
                <TableCell align="center" className={classes.tableCell}>
                  {renderSegments(user.queues)}
                </TableCell>
                <TableCell align="center" className={classes.tableCell}>
                  {formatToBrazilTime(user.updatedAt)}
                </TableCell>
                <TableCell align="center" className={classes.tableCell}>
                  {user.profile === "admin" ? "Administrador" : user.profile === "user" ? "Operador" : user.profile}
                </TableCell>
                <TableCell align="center" className={classes.tableCell}>
                  <div className={classes.actionButtons}>
                    <IconButton size="small" onClick={() => handleEditUser(user)}>
                      <FiEdit3 />
                    </IconButton>
                    <IconButton
                      size="small"
                      onClick={() => {
                        setConfirmModalOpen(true)
                        setDeletingUser(user)
                      }}
                    >
                      <GoTrash />
                    </IconButton>
                  </div>
                </TableCell>
              </TableRow>
            ))}
            {loading && <TableRowSkeleton columns={7} />}
          </TableBody>
        </Table>
      </Paper>
    </MainContainer>
  )
}

export default Users
