import React, { useState, useEffect, useContext } from "react";
import { toast } from "react-toastify";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Button from "@material-ui/core/Button";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import IconButton from "@material-ui/core/IconButton";
import SearchIcon from "@material-ui/icons/Search";
import TextField from "@material-ui/core/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";
import DeleteOutlineIcon from "@material-ui/icons/DeleteOutline";
import { FiEdit3 } from "react-icons/fi";
import MainContainer from "../../components/MainContainer";
import MainHeader from "../../components/MainHeader";
import MainHeaderButtonsWrapper from "../../components/MainHeaderButtonsWrapper";
import Title from "../../components/Title";
import api from "../../services/api";
import { i18n } from "../../translate/i18n";
import TableRowSkeleton from "../../components/TableRowSkeleton";
import ConfirmationModal from "../../components/ConfirmationModal";
import toastError from "../../errors/toastError";
import { AuthContext } from "../../context/Auth/AuthContext";
import BlackListModal from "../../components/BlackListModal";
import { GoTrash } from "react-icons/go";

const useStyles = makeStyles((theme) => ({
  mainPaper: {
    flex: 1,
    padding: theme.spacing(1),
    overflowY: "scroll",
    ...theme.scrollbarStyles,
  },
}));

const BlackList = () => {
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const [blacklist, setBlacklist] = useState([]);
  const [searchParam, setSearchParam] = useState("");
  const [selectedEntry, setSelectedEntry] = useState(null);
  const [entryModalOpen, setEntryModalOpen] = useState(false);
  const [confirmModalOpen, setConfirmModalOpen] = useState(false);
  const [deletingEntry, setDeletingEntry] = useState(null);
  const [selectedCompanyId, setSelectedCompanyId] = useState("");
  const { user } = useContext(AuthContext);

  const fetchBlacklist = async () => {
    try {
      setLoading(true);
      const url = "/blacklist";
      const params = {
        number: searchParam,
        companyId: user.companyId !== 1 ? user.companyId : selectedCompanyId || null,
      };
      const { data } = await api.get(url, { params });

      if (Array.isArray(data)) {
        setBlacklist(data);
      }
    } catch (err) {
      if (err.response && err.response.data && err.response.data.message) {
        toastError(err.response.data.message);
    } else {
        toastError(err);
    }
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchBlacklist();
  }, [searchParam, selectedCompanyId]);

  const handleAddOrUpdateEntry = (updatedEntry) => {
    if (!selectedEntry) {
      setBlacklist(prevBlacklist => [...prevBlacklist, updatedEntry]);
    } else {

      setBlacklist(prevBlacklist =>
        prevBlacklist.map(entry =>
          entry.number === selectedEntry.number ? updatedEntry : entry
        )
      );
    }
    fetchBlacklist();
  };
  const handleSearch = (event) => {
    setSearchParam(event.target.value.toLowerCase());
  };

  const handleOpenEntryModal = () => {
    setSelectedEntry(null);
    setEntryModalOpen(true);
  };

  const handleCloseEntryModal = () => {
    setSelectedEntry(null);
    setEntryModalOpen(false);
  };

  const handleEditEntry = (entry) => {
    setSelectedEntry(entry);
    setEntryModalOpen(true);
  };

  const handleDeleteEntry = async (entryNumber) => {
    try {
      await api.delete(`/blacklist/${entryNumber}`);
      toast.success(i18n.t("Número deletado da blacklist com sucesso!"));
      setBlacklist((prevBlacklist) =>
        prevBlacklist.filter((entry) => entry.number !== entryNumber)
      );
    } catch (err) {
      if (err.response && err.response.data && err.response.data.message) {
        toastError(err.response.data.message);
    } else {
        toastError(err);
    }
    }
    setDeletingEntry(null);
    setSearchParam("");
  };

  const formatToBrazilTime = (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleString("pt-BR", {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit",
    });
  };

  return (
    <MainContainer>
      <ConfirmationModal
        title={`${("Você tem certeza que deseja deletar o número: ")} ${deletingEntry?.number}?`}
        open={confirmModalOpen}
        onClose={() => setConfirmModalOpen(false)}
        onConfirm={() => handleDeleteEntry(deletingEntry.number)}
      >
        {i18n.t(`Clique em "OK" para realizar a remoção do número`)}
      </ConfirmationModal>

      <MainHeader>
        <Title>{i18n.t("BlackList")}</Title>
        <MainHeaderButtonsWrapper>
          <TextField
            placeholder={i18n.t("Pesquisar")}
            type="search"
            value={searchParam}
            onChange={handleSearch}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon style={{ color: "gray" }} />
                </InputAdornment>
              ),
            }}
          />
          <Button
            variant="contained"
            color="primary"
            onClick={handleOpenEntryModal}
            style={{ fontWeight: "bold" }}
          >
            {i18n.t("Adicionar Número")}
          </Button>
        </MainHeaderButtonsWrapper>
      </MainHeader>


      <Paper className={classes.mainPaper} variant="outlined" style={{ borderRadius: "15px" }}>
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell align="center">{i18n.t("Número")}</TableCell>
              <TableCell align="center">{i18n.t("Motivo")}</TableCell>
              <TableCell align="center">{i18n.t("Última Atualização")}</TableCell>
              <TableCell align="center">{i18n.t("Ações")}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {blacklist.map((entry) => (
              <TableRow key={entry.number}>
                <TableCell align="center">{entry.number}</TableCell>
                <TableCell align="center">{entry.reason || "Sem motivo"}</TableCell>
                <TableCell align="center">{formatToBrazilTime(entry.updatedAt)}</TableCell>
                <TableCell align="center">
                  <IconButton size="small" onClick={() => handleEditEntry(entry)}>
                    <FiEdit3 />
                  </IconButton>
                  <IconButton
                    size="small"
                    onClick={() => {
                      setConfirmModalOpen(true);
                      setDeletingEntry(entry);
                    }}
                  >
                    <GoTrash />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
            {loading && <TableRowSkeleton columns={4} />}
          </TableBody>
        </Table>
      </Paper>

      <BlackListModal
        open={entryModalOpen}
        onClose={handleCloseEntryModal}
        onAddOrUpdate={handleAddOrUpdateEntry}
        entry={selectedEntry}
      />
    </MainContainer>
  );
};

export default BlackList;
