"use client"

import React from "react"
import { useEffect, useState } from "react"
import { Dialog, Button, Checkbox, FormControlLabel } from "@material-ui/core"
import { makeStyles } from "@material-ui/styles"
import { IoClose } from "react-icons/io5"
import { FaSliders } from "react-icons/fa6"
import { PiUserListLight } from "react-icons/pi"
import { TiContacts } from "react-icons/ti"
import { listCompanies } from "../../services/company"

const useStyles = makeStyles(() => ({
  paper: {
    backgroundColor: "white",
    width: "537px",
    height: "596px",
    display: "flex",
    borderRadius: "15px",
    flexDirection: "column",
    position: "relative",
    boxShadow: "0 10px 25px rgba(0, 0, 0, 0.1)",
    overflow: "hidden",
  },
  header: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "16px 20px",
    width: "100%",
    borderBottom: "1px solid #eaeaea",
  },
  filterButton: {
    display: "flex",
    alignItems: "center",
    gap: "8px",
    color: "inherit",
    textTransform: "none",
  },
  title: {
    display: "flex",
    alignItems: "center",
    gap: "12px",
    fontSize: "20px",
    fontWeight: "600",
  },
  cardContainer: {
    display: "flex",
    justifyContent: "center",
    padding: "12px 8px",
    gap: "3rem",
    borderBottom: "1px solid #eaeaea",
    alignItems: "center",
    backgroundColor: "#f9f9f9",
  },
  cardContainerButton: {
    display: "flex",
    alignItems: "center",
    backgroundColor: "transparent",
    color: "#333",
    textTransform: "none",
    gap: "8px",
    padding: "8px 16px",
    borderRadius: "8px",
    transition: "all 0.2s ease",
    "&:hover": {
      backgroundColor: "rgba(0, 0, 0, 0.04)",
    },
    "&:focus": {
      outline: "none",
    },
  },
  selected: {
    color: "#D32929",
    fontWeight: "600",
    borderBottom: "2px solid #D32929",
    backgroundColor: "transparent",
    "&:hover": {
      backgroundColor: "transparent",
    },
  },
  disabled: {
    opacity: 0.5,
    cursor: "not-allowed",
    pointerEvents: "none",
  },
  selectedItems: {
    backgroundColor: "rgba(195, 87, 87, 0.1)",
    border: "1px solid #D32929",
    color: "#D32929",
    fontWeight: "500",
    "&:hover": {
      backgroundColor: "rgba(195, 87, 87, 0.15)",
      border: "1px solid #D32929",
    },
    "&:focus": {
      outline: "none",
    },
  },
  cardItems: {
    display: "grid",
    gridTemplateColumns: "1fr 1fr",
    gap: "12px",
    marginTop: "16px",
    width: "95%",
    margin: "auto",
    padding: "0 8px",
    maxHeight: "320px",
    overflowY: "auto",
    "&::-webkit-scrollbar": {
      width: "6px",
    },
    "&::-webkit-scrollbar-track": {
      background: "#f1f1f1",
      borderRadius: "10px",
    },
    "&::-webkit-scrollbar-thumb": {
      background: "#c1c1c1",
      borderRadius: "10px",
    },
  },
  pagination: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    padding: "12px 0",
    gap: "8px",
    fontSize: "14px",
    color: "rgba(0, 0, 0, 0.7)",
    borderTop: "1px solid #eaeaea",
  },
  paginationButton: {
    minWidth: "unset",
    padding: "4px 12px",
    borderRadius: "4px",
    color: "#666",
    "&:disabled": {
      color: "#ccc",
    },
  },
  button: {
    fontSize: "14px",
    padding: "8px 16px",
    borderRadius: "8px",
    transition: "all 0.2s ease",
    fontWeight: "500",
    textTransform: "none",
  },
  actions: {
    bottom: "0",
    display: "flex",
    justifyContent: "space-between",
    width: "100%",
    padding: "16px 20px",
    borderTop: "1px solid #eaeaea",
    backgroundColor: "#f9f9f9",
  },
  actionsButton: {
    display: "flex",
    justifyContent: "space-between",
    width: "100%",
  },
  buttonFunc: {
    padding: "8px 24px",
    borderRadius: "8px",
    fontSize: "14px",
    fontWeight: "500",
    textTransform: "none",
    boxShadow: "none",
    transition: "all 0.2s ease",
  },
  resetButton: {
    color: "#666",
    border: "1px solid #ddd",
    "&:hover": {
      backgroundColor: "#f5f5f5",
      borderColor: "#ccc",
    },
  },
  applyButton: {
    backgroundColor: "#D32929",
    color: "white",
    "&:hover": {
      backgroundColor: "#B71C1C",
    },
    "&:disabled": {
      backgroundColor: "#FFCDD2",
      color: "#D32F2F",
    },
  },
  divButton: {
    display: "flex",
    gap: "8px",
    alignItems: "center",
  },
  selectAllContainer: {
    display: "flex",
    alignItems: "center",
    width: "95%",
    marginLeft: "8px",
    marginBottom: "12px",
    padding: "0 8px",
  },
  checkbox: {
    color: "#D32929",
  },
  label: {
    fontSize: "14px",
    color: "#555",
    fontWeight: "500",
  },
  badge: {
    display: "inline-flex",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "#D32929",
    color: "white",
    borderRadius: "50%",
    width: "20px",
    height: "20px",
    fontSize: "12px",
    marginLeft: "8px",
  },
  connectionItem: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    width: "100%",
  },
  statusIndicator: {
    display: "inline-block",
    width: "8px",
    height: "8px",
    borderRadius: "50%",
    marginLeft: "8px",
  },
  statusConnected: {
    backgroundColor: "#4CAF50",
  },
  statusDisconnected: {
    backgroundColor: "#F44336",
  },
  statusQrcode: {
    backgroundColor: "#FFC107",
  },
  emptyState: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    padding: "40px 0",
    color: "#666",
    fontSize: "14px",
    textAlign: "center",
    width: "100%",
    gridColumn: "span 2",
  },
  closeButton: {
    padding: "8px",
    minWidth: "unset",
    borderRadius: "50%",
    "&:hover": {
      backgroundColor: "rgba(0, 0, 0, 0.04)",
    },
  },
}))

const FilterModal = ({ open, onClose, onApply }) => {
  const classes = useStyles()
  const [selected, setSelected] = useState("wallet")
  const [currentPage, setCurrentPage] = useState(1)
  const [companies, setCompanies] = useState([])
  const [filteredConnections, setFilteredConnections] = useState([])
  const [filteredOperators, setFilteredOperators] = useState([])
  const [selectedItems, setSelectedItems] = useState({
    wallet: [],
    connections: [],
    operators: [],
  })

  const itemsPerPage = 8

  useEffect(() => {
    const fetchCompanies = async () => {
      const data = await listCompanies() // Busca as empresas
      setCompanies(data)
    }
    fetchCompanies()
  }, [])

  useEffect(() => {
    if (!open) {
      handleReset()
    }
  }, [open, selectedItems.wallet])

  useEffect(() => {
    // Quando as carteiras selecionadas mudam, atualize as conexões e operadores
    if (selectedItems.wallet.length > 0) {
      filterConnectionsAndOperators()
    } else {
      // Se não houver carteiras selecionadas, limpe as conexões e operadores
      setFilteredConnections([])
      setFilteredOperators([])

      // Limpe também as seleções de conexões e operadores
      if (selectedItems.connections.length > 0 || selectedItems.operators.length > 0) {
        setSelectedItems({
          ...selectedItems,
          connections: [],
          operators: [],
        })
      }

      // Se estiver em uma aba que não seja wallet, volte para wallet
      if (selected !== "wallet") {
        setSelected("wallet")
      }
    }
  }, [selectedItems.wallet, selected])

  const totalPages = Math.ceil(
    (selected === "connections"
      ? filteredConnections.length
      : selected === "operators"
        ? filteredOperators.length
        : companies.length) / itemsPerPage,
  )

  const handleCardClick = (card) => {
    // Impedir a navegação para conexões ou operadores se não houver carteiras selecionadas
    if ((card === "connections" || card === "operators") && selectedItems.wallet.length === 0) {
      return
    }

    setSelected(card)
    setCurrentPage(1)
  }

  const visibleItems = (
    selected === "connections" ? filteredConnections : selected === "operators" ? filteredOperators : companies
  ).slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage)

  const filterConnectionsAndOperators = () => {
    const connectionsData = []
    const operatorsData = []

    selectedItems.wallet.forEach((walletName) => {
      const selectedCompany = companies.find((company) => company.name === walletName)
      if (selectedCompany) {
        connectionsData.push(...selectedCompany.whatsapps)
        operatorsData.push(...selectedCompany.users)
      }
    })

    setFilteredConnections(connectionsData)
    setFilteredOperators(operatorsData)
  }

  const handleItemSelect = (item, section) => {
    const itemIdentifier = section === "operators" || section === "connections" ? item.name : item
    const newSelection = selectedItems[section].includes(itemIdentifier)
      ? selectedItems[section].filter((selectedItem) => selectedItem !== itemIdentifier)
      : [...selectedItems[section], itemIdentifier]
    setSelectedItems({
      ...selectedItems,
      [section]: newSelection,
    })
    if (section === "operators" || section === "connections") {
      console.log(`Informações de ${section} selecionado(a):`, item)
    }
  }

  const handlePageChange = (direction) => {
    setCurrentPage((prev) => Math.max(1, Math.min(prev + direction, totalPages)))
  }

  const isApplyButtonDisabled = () => {
    return selectedItems.wallet.length === 0
  }

  const handleApplyFilters = () => {
    const filteredCompanies = companies.filter((company) => selectedItems.wallet.includes(company.name))

    const finalFilteredConnections = filteredConnections.filter((connection) =>
      selectedItems.connections.includes(connection.name),
    )

    const finalFilteredOperators = filteredOperators.filter((operator) =>
      selectedItems.operators.includes(operator.name),
    )

    onApply({
      companies: filteredCompanies,
      whatsAppSessions: finalFilteredConnections,
      users: finalFilteredOperators.map((operator) => ({ ...operator, id: operator.id })),
    })
    onClose()
  }

  const handleSelectAll = (section) => {
    const allItems =
      selected === "connections" ? filteredConnections : selected === "operators" ? filteredOperators : companies
    const selectedNames = allItems.map((item) =>
      section === "connections" || section === "operators" ? item.name : item.name,
    )
    const isAllSelected = selectedNames.every((name) => selectedItems[section].includes(name))
    if (isAllSelected) {
      setSelectedItems({
        ...selectedItems,
        [section]: [],
      })
    } else {
      setSelectedItems({
        ...selectedItems,
        [section]: selectedNames,
      })
    }
  }

  const handleReset = () => {
    setSelected("wallet")
    setSelectedItems({
      wallet: [],
      connections: [],
      operators: [],
    })
    setCurrentPage(1)
    setFilteredConnections([]) // Limpa as conexões
    setFilteredOperators([]) // Limpa os operadores
  }

  // Função para determinar a cor do status
  const getStatusColor = (status) => {
    switch (status) {
      case "DISCONNECTED":
        return classes.statusDisconnected
      case "CONNECTED":
        return classes.statusConnected
      case "QRCODE":
        return classes.statusQrcode
      default:
        return ""
    }
  }

  return (
    <Dialog open={open} onClose={onClose} aria-labelledby="filter-modal-title" classes={{ paper: classes.paper }}>
      <div className={classes.header}>
        <div className={classes.title}>
          <FaSliders size={20} />
          <span>Filtro</span>
        </div>
        <Button className={classes.closeButton} onClick={onClose}>
          <IoClose size={20} />
        </Button>
      </div>

      <div className={classes.cardContainer}>
        <Button
          className={`${classes.cardContainerButton} ${selected === "wallet" ? classes.selected : ""}`}
          onClick={() => handleCardClick("wallet")}
        >
          <div className={classes.divButton}>
            <PiUserListLight size={20} />
            Carteira
            {selectedItems.wallet.length > 0 && <span className={classes.badge}>{selectedItems.wallet.length}</span>}
          </div>
        </Button>
        <Button
          className={`${classes.cardContainerButton} ${selected === "connections" ? classes.selected : ""} ${selectedItems.wallet.length === 0 ? classes.disabled : ""}`}
          onClick={() => handleCardClick("connections")}
          disabled={selectedItems.wallet.length === 0}
        >
          <div className={classes.divButton}>
            <TiContacts size={20} />
            Conexões
            {selectedItems.connections.length > 0 && (
              <span className={classes.badge}>{selectedItems.connections.length}</span>
            )}
          </div>
        </Button>
        <Button
          className={`${classes.cardContainerButton} ${selected === "operators" ? classes.selected : ""} ${selectedItems.wallet.length === 0 ? classes.disabled : ""}`}
          onClick={() => handleCardClick("operators")}
          disabled={selectedItems.wallet.length === 0}
        >
          <div className={classes.divButton}>
            <TiContacts size={20} />
            Operadores
            {selectedItems.operators.length > 0 && (
              <span className={classes.badge}>{selectedItems.operators.length}</span>
            )}
          </div>
        </Button>
      </div>

      <div className={classes.selectAllContainer}>
        <FormControlLabel
          control={
            <Checkbox
              checked={
                selected === "wallet"
                  ? selectedItems.wallet.length === companies.length && companies.length > 0
                  : selected === "connections"
                    ? selectedItems.connections.length === filteredConnections.length && filteredConnections.length > 0
                    : selectedItems.operators.length === filteredOperators.length && filteredOperators.length > 0
              }
              onChange={() => handleSelectAll(selected)}
              size="small"
              className={classes.checkbox}
            />
          }
          label="Selecionar todos"
          classes={{ label: classes.label }}
        />
      </div>

      <div className={classes.cardItems}>
        {selected === "wallet" && companies.length === 0 && (
          <div className={classes.emptyState}>
            <p>Nenhuma carteira disponível</p>
          </div>
        )}

        {selected === "connections" && filteredConnections.length === 0 && (
          <div className={classes.emptyState}>
            <p>Selecione uma carteira para ver as conexões disponíveis</p>
          </div>
        )}

        {selected === "operators" && filteredOperators.length === 0 && (
          <div className={classes.emptyState}>
            <p>Selecione uma carteira para ver os operadores disponíveis</p>
          </div>
        )}

        {selected === "wallet" &&
          visibleItems.map((item, index) => (
            <Button
              key={index}
              className={`${classes.button} ${selectedItems.wallet.includes(item.name) ? classes.selectedItems : ""}`}
              variant="outlined"
              onClick={() => handleItemSelect(item.name, "wallet")}
            >
              {item.name}
            </Button>
          ))}

        {selected === "connections" &&
          visibleItems.map((item, index) => (
            <Button
              key={index}
              className={`${classes.button} ${selectedItems.connections.includes(item.name) ? classes.selectedItems : ""}`}
              variant="outlined"
              onClick={() => handleItemSelect(item, "connections")}
            >
              <div className={classes.connectionItem}>
                <span>{item.name}</span>
                <span className={`${classes.statusIndicator} ${getStatusColor(item.status)}`}></span>
              </div>
            </Button>
          ))}

        {selected === "operators" &&
          visibleItems.map((item, index) => (
            <Button
              key={index}
              className={`${classes.button} ${selectedItems.operators.includes(item.name) ? classes.selectedItems : ""}`}
              variant="outlined"
              onClick={() => handleItemSelect(item, "operators")}
            >
              {item.name}
            </Button>
          ))}
      </div>

      <div className={classes.pagination}>
        <Button
          variant="text"
          disabled={currentPage === 1}
          onClick={() => handlePageChange(-1)}
          className={classes.paginationButton}
        >
          Anterior
        </Button>
        <span>
          Página {currentPage} de {totalPages || 1}
        </span>
        <Button
          variant="text"
          disabled={currentPage === totalPages || totalPages === 0}
          onClick={() => handlePageChange(1)}
          className={classes.paginationButton}
        >
          Próxima
        </Button>
      </div>

      <div className={classes.actions}>
        <Button className={`${classes.buttonFunc} ${classes.resetButton}`} variant="outlined" onClick={handleReset}>
          Resetar
        </Button>
        <Button
          className={`${classes.buttonFunc} ${classes.applyButton}`}
          variant="contained"
          onClick={handleApplyFilters}
          disabled={isApplyButtonDisabled()}
        >
          Aplicar
        </Button>
      </div>
    </Dialog>
  )
}

export default FilterModal

